import React, { useEffect, useState } from "react";
import EditService from './edit-service.js';
import EditGoods from './edit-goods.js';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';  
import Button from '@mui/material/Button';



export default function EditVisitDiv({ visitInfo, setVisitInfo, servicesInfo, setServicesInfo, goodsInfo, setGoodsInfo, workersInfo, availableWorkers, setAvailableWorkers, setEditVisitModalOpen }) {

    const [availableServices, setAvailableServices] = useState([]);
    const [availableCashiers, setAvailableCashiers] = useState([]);
    const [availableAdmins, setAvailableAdmins] = useState([]);
    const [availableQualityControl, setAvailableQualityControl] = useState([]);
    const [availableGoods, setAvailableGoods] = useState([]);
    const api_url = process.env.REACT_APP_API_URL;
    const [saveStatus, setSaveStatus] = useState("");            //стейт для отображения какого-нибудь баннера после сохранения
    const [saveButtonDisable, setSaveButtonDisable] = useState(false);    //стейт, для отключения кнопки сохранить сразу после нажатия
    const complexName = localStorage.getItem('complex_name');
    const adminName = localStorage.getItem('admin_name');
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [deleteButtonDisable, setDeleteButtonDisable] = useState(false); 
    const role = localStorage.getItem('role');
    
    useEffect(() => {
      async function getAvailableServices() {      //получаем список всех доступных услуг для нашей категории авто и радиуса колеса
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
          body: JSON.stringify({ car_category: visitInfo.carCategory, tire_radius: visitInfo.tireRadius })
        };
        const availableServices = await (await fetch(api_url+"/api/availableServices", requestOptions)).json();
        setAvailableServices(availableServices && availableServices.map(({service_id, car_category, tire_radius, service_category, service_name, min_rank, price, salary}) => ({serviceId: service_id, carCategory: car_category, tireRadius: tire_radius, serviceCategory: service_category, serviceName: service_name, minRank: min_rank, price: price, salary: salary})));
      }

      async function getAvailableGoods() {      //получаем список всех доступных товаров
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
          body: JSON.stringify({ car_category: visitInfo.carCategory })
        };
        const availableGoods = await (await fetch(api_url+"/api/availableGoods", requestOptions)).json();
        setAvailableGoods(availableGoods && availableGoods.map(({goods_id, goods_category, goods_name, price, salary}) => ({goodsId: goods_id, goodsCategory: goods_category, goodsName: goods_name, price: price, salary: salary})));
      }

      async function getAvailableCashiers() {      //получаем список всех доступных кассиров
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
          body: JSON.stringify({ complex_name: complexName, role: "cashier" })
        };
        const availableCashiers = await (await fetch(api_url+"/api/availableWorkers", requestOptions)).json();
        setAvailableCashiers(availableCashiers.map(({worker_id, full_name, rank, phone_number, complex_name, role}) => ({workerId: worker_id, fullName: full_name, rank: rank, phoneNumber: phone_number, complexName: complex_name, role: role})));
      }

      async function getAvailableQualityControl() {      //получаем список всех доступных КК
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
          body: JSON.stringify({ complex_name: complexName, role: "quality_control" })
        };
        const availableQualityControl = await (await fetch(api_url+"/api/availableWorkers", requestOptions)).json();
        setAvailableQualityControl(availableQualityControl.map(({worker_id, full_name, rank, phone_number, complex_name, role}) => ({workerId: worker_id, fullName: full_name, rank: rank, phoneNumber: phone_number, complexName: complex_name, role: role})));
      }

      function servicePriceCount() {
        let currentPrice = 0
        for (var i = 0; i < servicesInfo?.length; i++) {
            currentPrice += servicesInfo[i]?.price * servicesInfo[i]?.serviceNumber;
        }
        for (var i = 0; i < goodsInfo?.length; i++) {
            currentPrice += goodsInfo[i]?.price * goodsInfo[i]?.goodsNumber;
        }
        setVisitInfo({
            ...visitInfo,
            totalAmount: currentPrice
        });
      }

      async function getAvailableAdmins() {      //получаем список всех доступных сотрудников
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
          body: JSON.stringify({ complex_name: complexName })
        };
        const availableAdmins = await (await fetch(api_url+"/api/availableAdmins", requestOptions)).json();
        setAvailableAdmins(availableAdmins.map(({user_id, fullname, complex}) => ({adminId: user_id, fullName: fullname, complexName: complex})));
      }

      getAvailableAdmins();
      getAvailableServices();
      getAvailableGoods();
      servicePriceCount();
      getAvailableQualityControl();
      getAvailableCashiers();
    }, [servicesInfo, goodsInfo, visitInfo.carCategory]);         


    function handleChange(e) {
        setVisitInfo({
          ...visitInfo,
          [e.target.name]: e.target.value
        });
    }

    const handleDeleteDialogOpen = () => {
      setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
      setDeleteDialogOpen(false);
    };

    async function deleteVisit() {
    
      setDeleteButtonDisable(true);                               //сразу выключаю кнопку удаления, чтобы не нажимали дважды

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
          body: JSON.stringify({ visit_id: visitInfo.visitId })
      };
      const delete_visit = await fetch(api_url+"/api/deleteVisit", requestOptions);

      const body = await delete_visit.json();
      if (body.message === "Visit deleted successfully!") {
        setSaveStatus("success");
        setDeleteDialogOpen(false);
        setEditVisitModalOpen(false);
      }
      else {
        setSaveStatus("error");
        setSaveButtonDisable(false);
      }
    }

    async function updateVisit(draftStatus) {

        const api_url = process.env.REACT_APP_API_URL;

        setSaveButtonDisable(true);                               //сразу выключаю кнопку сохранения, чтобы не нажимали дважды

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ visit_id: visitInfo.visitId, complex_name: visitInfo.complexName, client_name: visitInfo.clientName, car_number: visitInfo.carNumber, car_brand: visitInfo.carBrand, car_category: visitInfo.carCategory, tire_radius: visitInfo.tireRadius, chosen_services: servicesInfo, chosen_goods: goodsInfo, draft: draftStatus, card_amount: visitInfo?.cardAmount, cash_amount: visitInfo?.cashAmount, gift_amount: visitInfo?.giftAmount, agreement_amount: visitInfo?.agreementAmount, loyalty_card_amount: visitInfo?.loyaltyCardAmount, total_amount: visitInfo?.totalAmount, bonus_amount: visitInfo?.bonusAmount, chosen_workers: workersInfo, payment_method: visitInfo.paymentMethod, cashier: visitInfo?.cashierName, quality_control: visitInfo?.qualityControlName})
        };
        const saving_visit = await fetch(api_url+"/api/updateVisit", requestOptions);
        const body = await saving_visit.json();
        if (body.message === "Visit updated successfully!") {
          setSaveStatus("success");
          setEditVisitModalOpen(false);
        }
        else if (body.message === "Total amount error.") {
          setSaveButtonDisable(false);
          setSaveStatus("amount error");
        }
        else if (body.message === "Empty workers list.") {
          setSaveButtonDisable(false);
          setSaveStatus("empty workers list error");
        } 
        else {
          setSaveButtonDisable(false);
          setSaveStatus("error");
        }
    }

    return (
        <div className="add-visit-div">
            <h1 className="modal-header"> Изменить запись </h1>
            <div className="modal-form">

            {/* <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="admin-name-input" label="Администратор:" variant="outlined" name="adminName" value={adminName} InputProps={{ readOnly: true }} /> */}
            <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small"> 
                <InputLabel id="demo-simple-select-label">Администратор</InputLabel>
                <Select
                    key = {visitInfo.adminId}
                    variant="outlined"
                    id="admin_name"
                    name="adminId"
                    value={visitInfo.adminId}
                    label="Администратор"
                    // onChange={handleChange}
                    disabled
                    fullWidth
                >
                  {availableAdmins.map(({adminId, fullName}) => (                           //листим список админов
                    <MenuItem key={adminId} value={adminId}> {fullName} </MenuItem>
                  ))}
                  </Select>
              </FormControl>

            {/* Выбор филиала только для учетки офис */}
            { complexName=="Офис" && <>
            <FormControl sx={{mb:1.5, mt:1.5}} size="small" fullWidth > 
                <InputLabel id="complex_name_input">Выберите филиал</InputLabel>
                <Select
                    key={visitInfo.complexName}
                    variant="outlined"
                    id="complex_name"
                    name="complexName"
                    value={visitInfo.complexName}
                    label="Выберите филиал"
                    onChange={handleChange}
                >
                <MenuItem value={"Кудрово"}>Кудрово</MenuItem>
                <MenuItem value={"Охта"}>Охта</MenuItem>
                </Select> 
            </FormControl> 
            </>
            }

            {/* Если не офис, то выбора филиала нет */}
            { complexName!="Офис" && <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="complex-name-input" label="Комплекс:" variant="outlined" name="complexName" value={visitInfo.complexName} InputLabelProps={{ shrink: true }} InputProps={{readOnly: true}} onChange={handleChange} /> }
            
            <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small"> 
                <InputLabel id="demo-simple-select-label">Кассир</InputLabel>
                <Select
                    key = {visitInfo.cashierName}
                    variant="outlined"
                    id="cashier_name"
                    name="cashierName"
                    value={visitInfo?.cashierName}
                    label="Кассир"
                    onChange={handleChange}
                    fullWidth
                >
                  <MenuItem key="default_cashier_name" value="-"> - </MenuItem>
                  {availableCashiers.map(({workerId, fullName}) => (                           //листим список кассиров
                    <MenuItem key={workerId} value={workerId}> {fullName} </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small"> 
                <InputLabel id="demo-simple-select-label">Контроль качества</InputLabel>
                <Select
                    key = {visitInfo.qualityControlName}
                    variant="outlined"
                    name="qualityControlName"
                    value={visitInfo.qualityControlName}
                    label="Контроль качества"
                    onChange={handleChange}
                    fullWidth
                >
                  <MenuItem key="default_qc_name" value="-"> - </MenuItem>
                  {availableQualityControl.map(({workerId, fullName}) => (                           //листим список кассиров
                  <MenuItem key={workerId} value={workerId}> {fullName} </MenuItem>
                  ))}
                </Select>
              </FormControl>

            <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="client-name-input" label="Имя клиента:" variant="outlined" name="clientName" value={visitInfo.clientName} InputLabelProps={{ shrink: true }} onChange={handleChange} />
            <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="car-number-input" label="Номер авто:" variant="outlined" name="carNumber" value={visitInfo.carNumber}  InputLabelProps={{ shrink: true }} onChange={handleChange} />
            <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="car-brand-input" label="Марка авто:" variant="outlined" name="carBrand" value={visitInfo.carBrand} InputLabelProps={{ shrink: true }} onChange={handleChange} />
            <FormControl sx={{mb:1.5, mt:1.5}} size="small" fullWidth >
                  <InputLabel id="demo-simple-select-label">Выберите тип автомобиля</InputLabel>
                  <Select
                    key = {visitInfo.carCategory}
                    id="car-type"
                    name="carCategory"
                    defaultValue={visitInfo.carCategory}
                    value={visitInfo.carCategory}
                    label="Выберите тип автомобиля"
                    onChange={handleChange}
                  >
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                  <MenuItem value={"4"}>4</MenuItem>
                  </Select>
            </FormControl>
          </div>

          <div>
            <EditService availableServices={availableServices} servicesInfo={servicesInfo} setServicesInfo={setServicesInfo} availableWorkers={availableWorkers} setAvailableWorkers={setAvailableWorkers}/>
            <EditGoods availableGoods={availableGoods} goodsInfo={goodsInfo} setGoodsInfo={setGoodsInfo}/>
          </div>

          {saveStatus === "error" && <div className="button-div"><a>Ошибка, проверьте правильность введенных данных!</a></div>}
          {saveStatus === "amount error" && <div className="button-div"><a>Ошибка, проверьте сумму платежа!</a></div>}
          {saveStatus === "success" && <div className="button-div"><a>Запись успешно сохранена!</a></div>}
          {saveStatus === "empty workers list error" && <div className="button-div"><a>Необходимо выбрать работников!</a></div>}

          <div className="button-div">
          <button name="saveDraftButton" className="draft-button" onClick={() => updateVisit(true)}><a>Сохранить</a></button>
          <button name="saveButton" className="save-button" onClick={() => updateVisit(false)}><a>Заказ оплачен</a></button>
          

          { role=="2" && <Button variant="contained"
                  sx={{mb:1.5, mt:1.5}} fullWidth
                  color="warning"
                  onClick={handleDeleteDialogOpen}>
                  Удалить запись
                </Button> }
                <Dialog
                  open={deleteDialogOpen}
                  onClose={handleDeleteDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                  {"Вы действительно хотите удалить запись?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                Для удаления пользователя нажмите "Удалить", для отмены - нажмите "Отмена".
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDeleteDialogClose}>Отмена</Button>
                <Button variant="contained"
                  disabled={deleteButtonDisable}
                  color="warning"
                  onClick={() => deleteVisit()}>
                  Удалить
                  </Button>
                </DialogActions>
                </Dialog> 
          </div>
        </div>
    );
}