import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function EditAdvance({editedAdvanceId, availableWorkers}) {
    const [saveButtonDisable, setSaveButtonDisable] = useState(false); 
    const [saveStatus, setSaveStatus] = useState(""); 
    const api_url = process.env.REACT_APP_API_URL;
    const [advanceId, setAdvanceId] = useState("");
    const [advanceAmount, setAdvanceAmount] = useState("");
    const [complexName, setComplexName] = useState(""); 
    const [workerName, setWorkerName] = useState("");
    const [workerId, setWorkerId] = useState("");
    const [comment, setComment] = useState("");

        useEffect(() => {
        async function getAdvanceInfo() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ advance_id: editedAdvanceId })
          };
          const advance = await (await fetch(api_url+"/api/advanceInfo", requestOptions)).json();

          setAdvanceId(advance.advance_id);
          setAdvanceAmount(advance.advance_amount);
          setComplexName(advance.complex_name);
          setWorkerName(advance.worker_name);
          setWorkerId(advance.worker_id);
          setComment(advance.comment);
        }

        getAdvanceInfo();
        }, []);

        async function updateAdvanceInfo() {
    
            setSaveButtonDisable(true);                               //сразу выключаю кнопку сохранения, чтобы не нажимали дважды

            let worker_info = availableWorkers.find((s) => s.workerId === workerId)
            let worker_name = worker_info.fullName;
    
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
                body: JSON.stringify({ advance_id: advanceId, advance_amount: advanceAmount, complex_name: complexName, worker_id: workerId, worker_name: worker_name, comment: comment })
            };

            const saving_advance = await fetch(api_url+"/api/updateAdvanceInfo", requestOptions);

            const body = await saving_advance.json();
            if (body.message === "Advance info updated successfully!") {
              setSaveStatus("success");
            }
            else {
              setSaveStatus("error");
              setSaveButtonDisable(false);
            }
        }

        return (
            <div className='edit-worker-modal'>
                <h1 className="modal-header"> Изменить информацию о авансе </h1>
                <Box sx={{ maxWidth: 550, margin: `auto`, pt: 5, pb: 5 }}>
                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel id="demo-simple-select-label">Сотрудник:</InputLabel>
                <Select
                    value={workerId}
                    label="Сотрудник:"
                    name="workerId"
                    fullWidth
                    onChange={e => setWorkerId(e.target.value)}
                >
                    {availableWorkers.map(({workerId, fullName}) => (                           //листим каталог сотрудников
                    <MenuItem key={workerId} value={workerId}> {fullName} </MenuItem>
                    ))}
                </Select>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel htmlFor="component-outlined">Размер аванса</InputLabel>
                <OutlinedInput
                    label="Размер штрафа"
                    name="advanceAmount"
                    value={advanceAmount}
                    onChange={e => setAdvanceAmount(e.target.value)}
                />
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel htmlFor="component-outlined">Комментарий</InputLabel>
                <OutlinedInput
                    label="Комментарий"
                    name="comment"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                />
                </FormControl>
        
                <Button variant="contained"
                    sx={{mb:1.5, mt:1.5}} fullWidth
                    disabled={saveButtonDisable}
                    color="success"
                    className="mr10"
                    onClick={() => updateAdvanceInfo()}>Сохранить изменения</Button>

        {saveStatus === "error" && <Alert severity="error">Ошибка, проверьте правильность введенных данных!</Alert>}
        {saveStatus === "success" && <Alert severity="success">Изменения успешно сохранены!</Alert>}
        </Box>
            </div>
        );
}