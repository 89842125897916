import React, { useEffect, useState } from "react";
import EditVisitDiv from './edit-visit-div';
import EditPriceDiv from './edit-price-div';

export default function EditVisitModal({editedVisitId, setEditVisitModalOpen}) {
    const [chosenWorkers, setChosenWorkers] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState(false);  //следит за тем, чтобы количество введенных денежных средств соответствовало итоговой сумме
    const [visitInfo, setVisitInfo] = useState([]);
    const [servicesInfo, setServicesInfo] = useState([]);            //сюда записываем исходную инфу о сервисах
    const [goodsInfo, setGoodsInfo] = useState([]);
    const [workersInfo, setWorkersInfo] = useState([]);
    const api_url = process.env.REACT_APP_API_URL;
    const [availableWorkers, setAvailableWorkers] = useState();
    const complexName = localStorage.getItem('complex_name');

    useEffect(() => {
        async function getVisitInfo() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ visit_id: editedVisitId })
          };
          const visit = await (await fetch(api_url+"/api/visitInfo", requestOptions)).json();
          setVisitInfo({adminId: visit.administrator_id, visitId: visit.visit_id, complexName: visit.complex_name, clientName: visit.client_name, carNumber: visit.car_number, carBrand: visit.car_brand, carCategory: visit.car_category, tireRadius: visit.tire_radius, draft: visit.draft, totalAmount: visit.total_amount, bonusAmount: visit.bonus_amount, cardAmount: visit.card_amount, cashAmount: visit.cash_amount, agreementAmount: visit.agreement_amount, loyaltyCardAmount: visit.loyalty_card_amount, giftAmount: visit.gift_amount, chosenWorkers: visit.chosen_workers, chosenServices: visit.chosenServices, chosenGoods: visit.chosenGoods, paymentMethod: visit.payment_method, cashierName: visit.cashier, qualityControlName: visit.quality_control});
          setWorkersInfo(visit.chosen_workers);
          setServicesInfo(visit.chosen_services);
          setGoodsInfo(visit.chosen_goods);
        }
        getVisitInfo();

        async function getAvailableWorkers() {      //получаем список всех доступных сотрудников
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ complex_name: complexName, role: "car_washer" })
          };
          const availableWorkers = await (await fetch(api_url+"/api/availableWorkers", requestOptions)).json();
          setAvailableWorkers(availableWorkers.map(({worker_id, full_name, rank, phone_number, complex_name, role}) => ({workerId: worker_id, fullName: full_name, rank: rank, phoneNumber: phone_number, complexName: complex_name, role: role})));
        }
        getAvailableWorkers();
        }, []);

    return (
    <div>
    <div className='edit-visit-modal'>
    <EditPriceDiv visitInfo = {visitInfo} setVisitInfo = {setVisitInfo} />
    <EditVisitDiv setEditVisitModalOpen = {setEditVisitModalOpen} visitInfo = {visitInfo} setVisitInfo = {setVisitInfo} servicesInfo = {servicesInfo} setServicesInfo = {setServicesInfo} goodsInfo = {goodsInfo} setGoodsInfo = {setGoodsInfo}  workersInfo={workersInfo} availableWorkers={availableWorkers} setAvailableWorkers={setAvailableWorkers} />
    </div>
    </div>
    )
}