import logo from '../logo.png';
import Button from '@mui/material/Button';
import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import App from '../App';



export default function  Navbar({ setToken }) {
    const [role]=useState(localStorage.getItem('role'));
    const handleLogOut= () => {
        setToken('undefined');
        localStorage.clear();
      };
    return(
        <nav>
            <p><img className="nav-logo" src={logo} width="200" height="200" alt="Triton Logo"/></p>
                <Link className="nav-text" to="/">Главная</Link>
                <Link className="nav-text" to="/fines">Штрафы</Link>
                <Link className="nav-text" to="/bonuses">Премии</Link>
                <Link className="nav-text" to="/advances">Авансы</Link>
                <Link className="nav-text" to="/salary">Выплаты</Link>
                {role === "2" && <Link className="nav-text" to="/workers">Сотрудники</Link>}
                {role === "1" && <Link className="nav-text" to="/workerslp">Сотрудники</Link>}
                {role === "2" && <Link className="nav-text" to="/services">Услуги</Link>}
                {role === "2" && <Link className="nav-text" to="/goods">Товары</Link>}
                <Link className="nav-text" to="/finance-info">Финансы</Link>
                {role === "2" && <Link className="nav-text" to="/motivation">Мотивации</Link>}
                <div className='nav-logout'>
                <Button variant="contained" onClick={() => handleLogOut()}>Выйти</Button>
                </div>
        </nav>
    )
}