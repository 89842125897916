import React, { useEffect, useState } from "react";
import Navbar from './navbar';
import AddVisitDiv from './add-visit-div';
import PriceDiv from './price-div';

export default function AddVisit() {
    const [totalAmount, setTotalAmount] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState(false);  //следит за тем, чтобы количество введенных денежных средств соответствовало итоговой сумме
    const [newVisit, setNewVisit] = useState({
        complexName: localStorage.getItem('complex_name'),
        clientName: '',
        carNumber: '',
        carBrand: '',
        carCategory: 1,
        draftStatus: true,
        totalAmount: 0,
        bonusAmount: 0,
        cardAmount: 0,
        cashAmount: 0,
        giftAmount: 0,
        agreementAmount: 0,
        loyaltyCardAmount: 0,
        paymentMethod: "card",
        qualityControlName: "-",
        cashierName: "-"
    });

    useEffect(() => {
        const amountSum = parseInt(newVisit.bonusAmount) + parseInt(newVisit.cardAmount) + parseInt(newVisit.cashAmount) + parseInt(newVisit.giftAmount) + parseInt(newVisit.agreementAmount) + parseInt(newVisit.loyaltyCardAmount);
        if ( amountSum == newVisit.totalAmount){
            setPaymentStatus(true);
        }
        else {
            console.log("false" + amountSum + newVisit.totalAmount);
            setPaymentStatus(false);
        }

    }, [newVisit]);

    return (
    <div>
    <div className='edit-visit-modal'>
    <PriceDiv newVisit = {newVisit} setNewVisit = {setNewVisit} />
    <AddVisitDiv paymentStatus = {paymentStatus} newVisit = {newVisit} setNewVisit = {setNewVisit}/>
    </div>
    </div>
    )
}