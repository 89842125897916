import React, { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditServiceModal from './edit-service-modal';
import AddServiceModal from './add-service-modal';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';

export default function ServicesList() {

    const [allServices, setAllServices] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [addServiceOpen, setAddServiceOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleAddServiceOpen = () => setAddServiceOpen(true);
    const handleAddServiceClose = () => setAddServiceOpen(false);
    const api_url = process.env.REACT_APP_API_URL;
    const [editedServiceId, setEditedServiceId] = useState("");
    const [serviceCategory, setServiceCategory] = useState("any");
    const [complexName, setComplexName] = useState("any");

    useEffect(() => {
        async function getAllServicesList() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ service_category: serviceCategory, complex_name: complexName })
          };
          const allServices = await (await fetch(api_url+"/api/allServices", requestOptions)).json();
          setAllServices(allServices.map(({service_id, car_category, service_category, detailing, tire_radius, service_name, min_rank, price, salary, admin_salary, price_category}) => ({serviceId: service_id, carCategory: car_category, detailing: detailing, serviceCategory: service_category, tireRadius: tire_radius, serviceName: service_name, minRank: min_rank, priceCategory:price_category, price: price, salary: salary, admin_salary: admin_salary})));
        }
        getAllServicesList();
  
    }, [open, addServiceOpen, serviceCategory, complexName]);

    function showModal(service_id){
        handleOpen();
        setEditedServiceId(service_id);
    }

    function showAddServiceModal(){
        handleAddServiceOpen();
    }

    return (
        <>
            <head>
                <title>Услуги</title>
                <meta property="og:title" content="Dashboard" key="title" />
            </head>

            <Box sx={{pt:5, pb:5}} fullWidth>
            <FormControl sx={{m:1.5, width: 300}} >
            <InputLabel id="demo-simple-select-label">Категория:</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="service-category"
                name="serviceCategory"
                value={serviceCategory}
                label="Категория"
                onChange={e => setServiceCategory(e.target.value)}
            >
            <MenuItem value={"any"}>Все</MenuItem>
            <MenuItem value={"Комплексы"}>Комплексы</MenuItem>
            <MenuItem value={"Покрытия"}>Покрытия</MenuItem>
            <MenuItem value={"Химчистка"}>Химчистка</MenuItem>
            <MenuItem value={"Кузов"}>Кузов</MenuItem>
            <MenuItem value={"Диски"}>Диски</MenuItem>
            <MenuItem value={"Двигатель"}>Двигатель</MenuItem>
            <MenuItem value={"Салон"}>Салон</MenuItem>
            <MenuItem value={"Антидождь"}>Антидождь</MenuItem>
            <MenuItem value={"Детейлинг"}>Детейлинг</MenuItem>
            <MenuItem value={"АКЦИЯ"}>АКЦИЯ</MenuItem>
            <MenuItem value={"КлючАвто"}>КлючАвто</MenuItem>
            </Select>
            </FormControl>

            <FormControl sx={{m:1.5, width: 300}} >
            <InputLabel id="demo-simple-select-label">Комплекс:</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="complex_name"
                name="complexName"
                value={complexName}
                label="Комплекс"
                onChange={e => setComplexName(e.target.value)}
            >
            <MenuItem value={"any"}>Все</MenuItem>
            <MenuItem value={"ohta"}>Охта</MenuItem>
            <MenuItem value={"kudrovo"}>Кудрово</MenuItem>
            </Select>
            </FormControl>

            </Box>
            <button className='add-visit-button' onClick={() => showAddServiceModal()}>
                <a>Добавить услугу</a>
            </button>
            <div className='services-table'>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Тип авто</TableCell>
                    <TableCell>Категория</TableCell>
                    <TableCell>Детейлинг</TableCell>
                    <TableCell>Имя</TableCell>
                    <TableCell>Филиал</TableCell>
                    <TableCell>Стоимость</TableCell>
                    <TableCell>ЗП</TableCell>
                    <TableCell>ЗП Администратора</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {allServices.map(({ serviceId, carCategory, serviceCategory, detailing, serviceName, price, salary, admin_salary, priceCategory }) => (
                <TableRow>
                    <TableCell>{carCategory}</TableCell>
                    <TableCell>{serviceCategory}</TableCell>
                    { detailing == "yes" && <TableCell>Да</TableCell>}
                    { detailing == "no" && <TableCell>Нет</TableCell>}
                    <TableCell>{serviceName}</TableCell>
                    { priceCategory == "ohta_only" && <TableCell>Охта</TableCell>}
                    { priceCategory == "other" && <TableCell>Кудрово</TableCell>}
                    { priceCategory == "all" && <TableCell>Все филиалы</TableCell>}
                    <TableCell>{price}</TableCell>
                    <TableCell>{salary}</TableCell>
                    <TableCell>{admin_salary}</TableCell>
                    <TableCell>
                    <Button
                        variant="outlined"
                        className="mr10"
                        onClick={() => showModal(serviceId)}>Изменить</Button>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
            </div>
            <Modal
            open={open}
            onClose={handleClose}
            >
            <EditServiceModal editedServiceId={editedServiceId} setEditWindowOpen={setOpen}/>
            </Modal>

            <Modal
            open={addServiceOpen}
            onClose={handleAddServiceClose}
            >
            <AddServiceModal />
            </Modal>
        </>
    
    )

}

