import React, { useEffect, useState } from "react";
import DashboardTable from './dashboard-table';
import MotivationalPlanWindow from './motivational-plan-window';
import AddVisit from './add-visit';
import MoneyWindow from './money-window';
import Modal from '@mui/material/Modal';
import moment from 'moment';


export default function Dashboard() {
    // const [newRecord, setNewRecord] = useState(false);
    const [chosenWorkers, setChosenWorkers] = useState([]);
    const [moneyStatus, setMoneyStatus] = useState({total_amount: 0, card_amount: 0, cash_amount: 0, bonus_amount: 0, gift_amount: 0, agreement_amount: 0, loyalty_card_amount: 0});
    const [filterInfo, setFilterInfo] = useState({clientName: "", carNumber: "", workerId: "any", startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD"), complexName: "all", paymentMethod: "any"});  //фильтр для отображения данных в зависимости от выбранных параметров
    const [newRecordOpen, setNewRecordOpen] = useState(false);
    const handleNewRecordOpen = () => setNewRecordOpen(true);
    const handleNewRecordClose = () => setNewRecordOpen(false);
    const [userFinanceInfo, setUserFinanceInfo] = useState({admin_turnover_plan: 0, total_amount: 0, admin_average_bill_plan:0, average_bill:0, admin_visits_number_plan:0, visits_number:0});
    const api_url = process.env.REACT_APP_API_URL;

    function showModal(){
        handleNewRecordOpen();
    }

    useEffect(() => {
        if (localStorage.getItem('chosenWorkers') !== null) {
            setChosenWorkers(JSON.parse(localStorage.getItem('chosenWorkers')));          //попробуем сетить тут стейт имен выбранных сотрудников
        }
        async function getUserFinanceInfo() {      //получаем информацию о мотивациях данного пользователя
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') }
            };
            const myFinanceInfo = await (await fetch(api_url+"/api/myFinanceInfo", requestOptions)).json();
            setUserFinanceInfo(myFinanceInfo);
        }
        getUserFinanceInfo();
    }, [newRecordOpen]);

    return (
        <>
            <head>
                <title>Dashboard</title>
                <meta property="og:title" content="Dashboard" key="title" />
            </head>
            <button className='add-visit-button' onClick={() => showModal()}>
                <a>Добавить запись</a>
            </button>
            <div>
            <Modal
            open={newRecordOpen}
            onClose={handleNewRecordClose}
            >
            <AddVisit />
            </Modal>
            </div>
            <div className="dashboard">
                <MotivationalPlanWindow userFinanceInfo = {userFinanceInfo}/>
                <DashboardTable moneyStatus = {moneyStatus} setMoneyStatus = {setMoneyStatus} filterInfo={filterInfo} setFilterInfo={setFilterInfo}/>
                <MoneyWindow moneyStatus = {moneyStatus}/>
            </div>
        </>
    )
}

