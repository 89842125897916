import React, { useEffect } from "react";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

export default function PriceDiv({newVisit, setNewVisit}) {

    function setPaymentByCard() {
        setNewVisit({
            ...newVisit,
            cardAmount: newVisit?.totalAmount,
            cashAmount: 0,
            bonusAmount: 0,
            giftAmount: 0,
            agreementAmount: 0,
            loaltyCardAmount: 0,
            paymentMethod: "card"
        });
    }

    function setPaymentByCash() {
        setNewVisit({
            ...newVisit,
            cashAmount: newVisit?.totalAmount,
            cardAmount: 0,
            bonusAmount: 0,
            giftAmount: 0,
            agreementAmount: 0,
            loaltyCardAmount: 0,
            paymentMethod: "cash"
        });
    }

    function setMixedPayment() {
        setNewVisit({
            ...newVisit,
            paymentMethod: "mixed"
        });
    }

    useEffect(() => {

        async function checkPaymentMethod() {
          if (newVisit.paymentMethod === "card") { 
            setPaymentByCard();
          }
          else if (newVisit.paymentMethod === "cash") { 
            setPaymentByCash();
          }
          else if (newVisit.paymentMethod === "mixed") { 
            setMixedPayment();
          }
        }
        checkPaymentMethod()

    }, [newVisit.paymentMethod, newVisit.totalAmount]);

    function handleChange(e) {
        setNewVisit({
          ...newVisit,
          [e.target.name]: e.target.value
        });
    }


    return(
        <div className="price-div">
            <h1 className="choose-worker-header"> Итоговая сумма: </h1>
            <h1 className="choose-worker-header">{newVisit?.totalAmount}</h1>
            <FormControl sx={{mb:5, mt:4}}>
            <InputLabel id="payment-method-label">Способ оплаты:</InputLabel>
            <Select
                    label="Способ оплаты:"
                    name="paymentMethod"
                    value={newVisit.paymentMethod}
                    sx={{width:200}}
                    onChange={e => handleChange(e)}
                >
                <MenuItem key={1} value="card"> По карте </MenuItem>
                <MenuItem key={2} value="cash"> Наличными </MenuItem>
                <MenuItem key={3} value="mixed"> Смешанная оплата </MenuItem>
            </Select>
            </FormControl>
            {newVisit.paymentMethod === "mixed" && <div className="mixed-payment-div">
            <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата безналом:" variant="outlined" name="cardAmount" value={newVisit.cardAmount} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата наличными:" variant="outlined" name="cashAmount" value={newVisit.cashAmount} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата бонусами:" variant="outlined" name="bonusAmount" value={newVisit.bonusAmount} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата сертификатом:" variant="outlined" name="giftAmount" value={newVisit.giftAmount} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата по договору:" variant="outlined" name="agreementAmount" value={newVisit.agreementAmount} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата картой лояльности:" variant="outlined" name="loyaltyCardAmount" value={newVisit.loyaltyCardAmount} onChange={handleChange} />
            </div>}
        </div>
    )
}