import React, { useEffect, useState } from "react";
import AddSalary from './add-salary';
import EditSalary from './edit-salary';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';


export default function Salary() {
    const [addSalaryOpen, setAddSalaryOpen] = useState(false);
    const [editSalaryOpen, setEditSalaryOpen] = useState(false);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [workerFilter, setWorkerFilter] = useState("all");
    const [editedSalaryId, setEditedSalaryId] = useState("");
    const [availableWorkers, setAvailableWorkers] = useState([]);
    const [salariesList, setSalariesList] = useState([]);
    const api_url = process.env.REACT_APP_API_URL;
    const complexName = localStorage.getItem('complex_name');
    const [totalSalary, setTotalSalary] = useState(0);

    const handleAddSalaryOpen = () => setAddSalaryOpen(true);
    const handleAddSalaryClose = () => setAddSalaryOpen(false);

    const handleEditSalaryOpen = () => setEditSalaryOpen(true);
    const handleEditSalaryClose = () => setEditSalaryOpen(false);

    function showEditSalaryModal(salary_id){
        handleEditSalaryOpen();
        setEditedSalaryId(salary_id);
    }


    function showAddSalaryModal(){
        handleAddSalaryOpen();
    }

    React.useEffect(() => {                                         //функция, которая вызывается при загрузке страницы
        async function getAvailableWorkers() {      //получаем список всех доступных сотрудников
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
              body: JSON.stringify({ complex_name: complexName })
            };
            const availableWorkers = await (await fetch(api_url+"/api/allWorkers", requestOptions)).json();
            setAvailableWorkers(availableWorkers.map(({worker_id, full_name, rank, phone_number, complex_name, role}) => ({workerId: worker_id, fullName: full_name, rank: rank, phoneNumber: phone_number, complexName: complex_name, role: role})));
        }
    
        async function getSalaries() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token')},
            body: JSON.stringify({ start_date: startDate, end_date: endDate, worker_id: workerFilter, complex_name: complexName})
          };
          const salaries_list = await fetch(api_url+"/api/allSalaries", requestOptions);
          const body = await salaries_list.json();
          setSalariesList(body.map(({ salary_id, salary_date, worker_id, full_name, salary_amount, complex_name, comment }) => ({ salary_id: salary_id, salary_date: new Date(salary_date).toLocaleString("ru-RU", { timeZone: "UTC" }), worker_id: worker_id, worker_name: full_name, salary_amount: salary_amount, complex_name: complex_name, comment: comment })));
          let temp_total_salary = 0;

          for(var i=0; i < body.length; i++){
             temp_total_salary = temp_total_salary + body[i].salary_amount;
          }

          setTotalSalary(temp_total_salary);
        }
        getAvailableWorkers();
        getSalaries();
    
        }, [startDate, endDate, workerFilter, addSalaryOpen, editSalaryOpen]);

    return (
        <>
            <head>
                <title>Выплаты ЗП</title>
                <meta property="og:title" content="Salaries" key="title" />
            </head>

            <Box sx={{pt:5, pb:5}} fullWidth>
            <TextField
                id="start_date"
                label="Start Date"
                type="date"
                defaultValue={startDate}
                sx={{ m: 1.5, width: 220}}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setStartDate(e.target.value)}
            />

            <TextField
                id="end_date"
                label="End Date"
                type="date"
                defaultValue={endDate}
                sx={{ m: 1.5, width: 220 }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setEndDate(e.target.value)}
            />

            <FormControl sx={{m: 1.5, width: 250}} >
            <InputLabel id="demo-simple-select-label">Выберите сотрудника</InputLabel>
            <Select
                id="worker_name"
                value={workerFilter}
                label="Выберите сотрудника"
                onChange={e => setWorkerFilter(e.target.value)}
            >
            <MenuItem value="all">Любой</MenuItem>
            {availableWorkers.map((item) => (
            <MenuItem key={item.workerId} value={item.workerId}>{item.fullName}</MenuItem>
            ))}
            </Select>
            </FormControl>
            </Box>


            <button className='add-visit-button' onClick={() => showAddSalaryModal()}>
                <a>Добавить выплату ЗП</a>
            </button>

            <Typography m={5} variant="h6">ЗП за выбранный период: {totalSalary}</Typography>
            

            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Сотрудник</TableCell>
                <TableCell>Размер выплаты</TableCell>
                {/* <TableCell>Филиал</TableCell> */}
                <TableCell>Комментарий</TableCell>
                <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {salariesList.map(({salary_date, salary_id, worker_name, salary_amount, comment}) => (
            <TableRow>
                <TableCell>{salary_date}</TableCell>
                <TableCell>{worker_name}</TableCell>
                <TableCell>{salary_amount}</TableCell>
                {/* <TableCell>{complex_name}</TableCell> */}
                <TableCell sx={{whiteSpace: "normal", wordWrap: "break-word"}}>{comment}</TableCell>
                <TableCell>
                <Button
                    variant="outlined"
                    className="mr10"
                    onClick={() => showEditSalaryModal(salary_id)}>Изменить</Button>
                </TableCell>
            </TableRow>
            ))}
            </TableBody>
            </Table>
            </TableContainer>
            

            <Modal
            open={addSalaryOpen}
            onClose={handleAddSalaryClose}
            >
            <AddSalary availableWorkers={availableWorkers}/>
            </Modal>

            <Modal
            open={editSalaryOpen}
            onClose={handleEditSalaryClose}
            >
            <EditSalary editedSalaryId={editedSalaryId} availableWorkers={availableWorkers}/>
            </Modal>
        </>
    )
}

