import React, { useEffect, useState } from "react";
import { LinearProgress, Box, Typography } from '@mui/material';


export default function MotivationalPlanWindow({ userFinanceInfo }) {

    function ProgressBar({ value, max }) {
        const formattedValue = value?.toLocaleString('ru-RU');
        const formattedMax = max?.toLocaleString('ru-RU');
        const normalise = (value) => ((value ?? 0) - 0) * 100 / ((max ?? 0) - 0);
        return (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box width="70%">
              <LinearProgress variant="determinate" value={normalise(value) } />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{formattedValue + "/" + formattedMax}</Typography>
            </Box>
          </Box>
        );
      }

    return (
        <div className="motivational-window">
            <h1 className="choose-worker-header"> Оборот за месяц: </h1><br></br>
            <ProgressBar value={userFinanceInfo.total_amount} max={userFinanceInfo.admin_turnover_plan} /><br></br>
            <h1 className="choose-worker-header"> Средний чек: </h1><br></br>
            <ProgressBar value={userFinanceInfo.average_bill} max={userFinanceInfo.admin_average_bill_plan} /><br></br>
            <h1 className="choose-worker-header"> Количество машин: </h1><br></br>
            <ProgressBar value={userFinanceInfo.visits_number} max={userFinanceInfo.admin_visits_number_plan} />
        </div>
    );
}