import React, { useEffect, useState } from "react";
import AddFine from './add-fine';
import EditFine from './edit-fine';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';



export default function Fines() {
    const [addFineOpen, setAddFineOpen] = useState(false);
    const [editFineOpen, setEditFineOpen] = useState(false);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [workerFilter, setWorkerFilter] = useState("all");
    const [editedFineId, setEditedFineId] = useState("");
    const [availableWorkers, setAvailableWorkers] = useState([]);
    const [finesList, setFinesList] = useState([]);
    const api_url = process.env.REACT_APP_API_URL;
    const complexName = localStorage.getItem('complex_name');
    const [totalFines, setTotalFines] = useState(0);                        //всего штрафов

    const handleAddFineOpen = () => setAddFineOpen(true);
    const handleAddFineClose = () => setAddFineOpen(false);

    const handleEditFineOpen = () => setEditFineOpen(true);
    const handleEditFineClose = () => setEditFineOpen(false);

    function showEditFineModal(fine_id){
        handleEditFineOpen();
        setEditedFineId(fine_id);
    }


    function showAddFineModal(){
        handleAddFineOpen();
    }

    React.useEffect(() => {                                         //функция, которая вызывается при загрузке страницы
        async function getAvailableWorkers() {      //получаем список всех доступных сотрудников
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
              body: JSON.stringify({ complex_name: complexName })
            };
            const availableWorkers = await (await fetch(api_url+"/api/allWorkers", requestOptions)).json();
            setAvailableWorkers(availableWorkers.map(({worker_id, full_name, rank, phone_number, complex_name, role}) => ({workerId: worker_id, fullName: full_name, rank: rank, phoneNumber: phone_number, complexName: complex_name, role: role})));
        }
    
        async function getFines() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token')},
            body: JSON.stringify({ start_date: startDate, end_date: endDate, worker_id: workerFilter, complex_name: complexName})
          };
          const fines_list = await fetch(api_url+"/api/allFines", requestOptions);
          const body = await fines_list.json();
          setFinesList(body.map(({ fine_id, fine_date, worker_id, full_name, fine_amount, complex_name, comment }) => ({ fine_id: fine_id, fine_date: new Date(fine_date).toLocaleString("ru-RU", { timeZone: "UTC" }), worker_id: worker_id, worker_name: full_name, fine_amount: fine_amount, complex_name: complex_name, comment: comment })));
          let temp_total_fines = 0;

          for(var i=0; i < body.length; i++){
             temp_total_fines = temp_total_fines + body[i].fine_amount;
          }

          setTotalFines(temp_total_fines);
        }
        getAvailableWorkers();
        getFines();
    
        }, [startDate, endDate, workerFilter, addFineOpen, editFineOpen]);

    return (
        <>
            <head>
                <title>Штрафы</title>
                <meta property="og:title" content="Dashboard" key="title" />
            </head>

            <Box sx={{pt:5, pb:5}} fullWidth>
            <TextField
                id="start_date"
                label="Start Date"
                type="date"
                defaultValue={startDate}
                sx={{ m: 1.5, width: 220}}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setStartDate(e.target.value)}
            />

            <TextField
                id="end_date"
                label="End Date"
                type="date"
                defaultValue={endDate}
                sx={{ m: 1.5, width: 220 }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setEndDate(e.target.value)}
            />

            <FormControl sx={{m: 1.5, width: 250}} >
            <InputLabel id="demo-simple-select-label">Выберите сотрудника</InputLabel>
            <Select
                id="worker_name"
                value={workerFilter}
                label="Выберите сотрудника"
                onChange={e => setWorkerFilter(e.target.value)}
            >
            <MenuItem value="all">Любой</MenuItem>
            {availableWorkers.map((item) => (
            <MenuItem key={item.workerId} value={item.workerId}>{item.fullName}</MenuItem>
            ))}
            </Select>
            </FormControl>
            </Box>

            <button className='add-visit-button' onClick={() => showAddFineModal()}>
                <a>Добавить штраф</a>
            </button>

            <Typography m={5} variant="h6">Штрафы за выбранный период: {totalFines}</Typography>

            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {/* <div className='workers-table'> */}
            <TableHead>
            <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Сотрудник</TableCell>
                <TableCell>Размер Штрафа</TableCell>
                {/* <TableCell>Филиал</TableCell> */}
                <TableCell>Комментарий</TableCell>
                <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {finesList.map(({fine_date, fine_id, worker_name, fine_amount, comment}) => (
            <TableRow>
                <TableCell>{fine_date}</TableCell>
                <TableCell>{worker_name}</TableCell>
                <TableCell>{fine_amount}</TableCell>
                {/* <TableCell>{complex_name.join(', ')}</TableCell> */}
                <TableCell sx={{whiteSpace: "normal", wordWrap: "break-word"}}>{comment}</TableCell>
                <TableCell>
                <Button
                    variant="outlined"
                    className="mr10"
                    onClick={() => showEditFineModal(fine_id)}>Изменить</Button>
                </TableCell>
            </TableRow>
            ))}
            </TableBody>
            {/* </div> */}
            </Table>
            </TableContainer>

            <Modal
            open={addFineOpen}
            onClose={handleAddFineClose}
            >
            <AddFine availableWorkers={availableWorkers}/>
            </Modal>

            <Modal
            open={editFineOpen}
            onClose={handleEditFineClose}
            >
            <EditFine editedFineId={editedFineId} availableWorkers={availableWorkers}/>
            </Modal>
        </>
    )
}

