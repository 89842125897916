import './App.css';
import React, { useEffect, useState } from "react";
import Dashboard from './pages/dashboard';
import Navbar from './pages/navbar';
import Login from './pages/login';
import Advances from './pages/advances';
import Salary from './pages/salary';
import useToken from './pages/use-token';
import { BrowserRouter, Router, Route, Routes, Link } from 'react-router-dom';
import WorkersList from './pages/workers-list';
import WorkersListLP from './pages/workers-list-lp';
import ServicesList from './pages/services-list';
import GoodsList from './pages/goods-list';
import Bonuses from './pages/bonuses';
import Fines from './pages/fines';
import FinanceInfo from './pages/finance-info';
import MotivationInfo from './pages/motivation_info';

export default function App() {
  const {token, setToken} =  useToken();

  if(!token || token === 'undefined') {
    return <Login setToken={setToken} />
  }

  else {
    return (
    <div>
    <BrowserRouter>
    <Navbar setToken={setToken}/>
        <Routes>
          <Route path="/" element={<Dashboard/>} />
          <Route path="/fines" element={<Fines/>} />
          <Route path="/bonuses" element={<Bonuses/>} />
          <Route path="/advances" element={<Advances/>} />
          <Route path="/salary" element={<Salary/>} />
          <Route path="/workers" element={<WorkersList/>} />
          <Route path="/workerslp" element={<WorkersListLP/>} />
          <Route path="/services" element={<ServicesList/>} />
          <Route path="/goods" element={<GoodsList/>} />
          <Route path="/finance-info" element={<FinanceInfo/>} />
          <Route path="/motivation" element={<MotivationInfo/>} />
        </Routes>
      </BrowserRouter>
    </div>
    )
  }
}