import React, { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditGoodsModal from './edit-goods-modal';
import AddGoodsModal from './add-goods-modal';
import Button from '@mui/material/Button';

export default function GoodsList() {

    const [allGoods, setAllGoods] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [addGoodsOpen, setAddGoodsOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleAddGoodsOpen = () => setAddGoodsOpen(true);
    const handleAddGoodsClose = () => setAddGoodsOpen(false);
    const api_url = process.env.REACT_APP_API_URL;
    const [editedGoodsId, setEditedGoodsId] = useState("");

    useEffect(() => {
        async function getAllGoodsList() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ complex_name: 'Охта' })
          };
          const allGoods = await (await fetch(api_url+"/api/allGoods", requestOptions)).json();
          setAllGoods(allGoods.map(({ goods_id, goods_category, goods_name, price, salary, price_category }) => ({goodsId: goods_id, goodsCategory: goods_category, goodsName: goods_name, price: price, priceCategory: price_category, salary: salary })));
        }
        getAllGoodsList();
  
    }, [open, addGoodsOpen]);

    function showModal(goods_id){
        handleOpen();
        setEditedGoodsId(goods_id);
    }

    function showAddGoodsModal(){
        handleAddGoodsOpen();
    }

    return (
        <div>
            <button className='add-visit-button' onClick={() => showAddGoodsModal()}>
                <a>Добавить товар</a>
            </button>
            <div className='services-table'>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Категория</TableCell>
                    <TableCell>Наименование</TableCell>
                    <TableCell>Филиал</TableCell>
                    <TableCell>Стоимость</TableCell>
                    <TableCell>ЗП</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {allGoods.map(({ goodsId, goodsCategory, goodsName, price, salary, priceCategory }) => (
                <TableRow>
                    <TableCell>{goodsCategory}</TableCell>
                    <TableCell>{goodsName}</TableCell>
                    { priceCategory == "ohta_only" && <TableCell>Охта</TableCell>}
                    { priceCategory == "other" && <TableCell>Кудрово</TableCell>}
                    { priceCategory == "all" && <TableCell>Все филиалы</TableCell>}
                    <TableCell>{price}</TableCell>
                    <TableCell>{salary}</TableCell>
                    <TableCell>
                    <Button
                        variant="outlined"
                        className="mr10"
                        onClick={() => showModal(goodsId)}>Изменить</Button>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
            </div>
            <Modal
            open={open}
            onClose={handleClose}
            >
            <EditGoodsModal editedGoodsId={editedGoodsId}/>
            </Modal>

            <Modal
            open={addGoodsOpen}
            onClose={handleAddGoodsClose}
            >
            <AddGoodsModal />
            </Modal>
        </div>
    )
}

