import React, { useEffect, useState } from "react";
import AddService from './add-service.js';
import AddGoods from './add-goods.js';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

export default function AddVisitDiv({ newVisit, setNewVisit, totalAmount, setTotalAmount, paymentStatus }) {

    const [availableServices, setAvailableServices] = useState([]);
    const [availableCashiers, setAvailableCashiers] = useState([]);
    const [availableQualityControl, setAvailableQualityControl] = useState([]);
    const [availableGoods, setAvailableGoods] = useState([]);
    const [chosenServices, setChosenServices]=useState([{serviceId: "-", serviceNumber: 1, price: 0, salary: 0, adminSalary: 0, minRank:1, chosenWorkers: []}]);        //выбранные услуги (по умолчанию стоит пустая услуга)
    const [chosenGoods, setChosenGoods]=useState([{goodsId: "1", goodsNumber: 1, price: 0, salary: 0}]);              //выбранные товары (по умолчанию стоит пустой товар)
    const api_url = process.env.REACT_APP_API_URL;
    const [saveStatus, setSaveStatus] = useState("");            //стейт для отображения какого-нибудь баннера после сохранения
    const [saveButtonDisable, setSaveButtonDisable] = useState(false);    //стейт, для отключения кнопки сохранить сразу после нажатия
    const complexName = localStorage.getItem('complex_name');
    const adminName = localStorage.getItem('admin_name');
    const [cashierName, setCashierName]=useState(localStorage.getItem('cashier_name'));
    const [qualityControlName, setQualityControlName]=useState(localStorage.getItem('quality_control_name'));

    useEffect(() => {
      async function getAvailableServices() {      //получаем список всех доступных услуг для нашей категории авто и радиуса колеса
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
          body: JSON.stringify({ car_category: newVisit?.carCategory })
        };
        const availableServices = await (await fetch(api_url+"/api/availableServices", requestOptions)).json();
        setAvailableServices(availableServices && availableServices.map(({service_id, car_category, service_category, service_name, min_rank, price, admin_salary, salary}) => ({serviceId: service_id, carCategory: car_category, serviceCategory: service_category, serviceName: service_name, minRank: min_rank, price: price, adminSalary: admin_salary, salary: salary})));
      }

      async function getAvailableGoods() {      //получаем список всех доступных товаров
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
          body: JSON.stringify({ car_category: newVisit?.carCategory })
        };
        const availableGoods = await (await fetch(api_url+"/api/availableGoods", requestOptions)).json();
        setAvailableGoods(availableGoods && availableGoods.map(({goods_id, goods_category, goods_name, price, salary}) => ({goodsId: goods_id, goodsCategory: goods_category, goodsName: goods_name, price: price, salary: salary})));
      }

      async function getAvailableCashiers() {      //получаем список всех доступных кассиров
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
          body: JSON.stringify({ complex_name: complexName, role: "cashier" })
        };
        const availableCashiers = await (await fetch(api_url+"/api/availableWorkers", requestOptions)).json();
        setAvailableCashiers(availableCashiers.map(({worker_id, full_name, rank, phone_number, complex_name, role}) => ({workerId: worker_id, fullName: full_name, rank: rank, phoneNumber: phone_number, complexName: complex_name, role: role})));
      }

      async function getAvailableQualityControl() {      //получаем список всех доступных КК
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
          body: JSON.stringify({ complex_name: complexName, role: "quality_control" })
        };
        const availableQualityControl = await (await fetch(api_url+"/api/availableWorkers", requestOptions)).json();
        setAvailableQualityControl(availableQualityControl.map(({worker_id, full_name, rank, phone_number, complex_name, role}) => ({workerId: worker_id, fullName: full_name, rank: rank, phoneNumber: phone_number, complexName: complex_name, role: role})));
      }


      function servicePriceCount() {
        let currentPrice = 0
        for (var i = 0; i < chosenServices?.length; i++) {
            currentPrice += chosenServices[i].price * chosenServices[i].serviceNumber;
        }
        for (var i = 0; i < chosenGoods?.length; i++) {
            currentPrice += chosenGoods[i].price * chosenGoods[i].goodsNumber;
        }
        setNewVisit({
            ...newVisit,
            totalAmount: currentPrice
        });
      }

      getAvailableServices();
      getAvailableGoods();
      servicePriceCount();
      getAvailableCashiers();
      getAvailableQualityControl();

    }, [newVisit?.carCategory, chosenServices, chosenGoods]);         


    function handleChange(e) {
        setNewVisit({
          ...newVisit,
          [e.target.name]: e.target.value
        });
    }

    function handleCashierChange(e) {
      setNewVisit({
        ...newVisit,
        [e.target.name]: e.target.value,
      });
      setCashierName(e.target.value);
      localStorage.setItem('cashier_name', e.target.value);
    }

    function handleQualityControlChange(e) {
      setNewVisit({
        ...newVisit,
        [e.target.name]: e.target.value
      });
      setQualityControlName(e.target.value);
      localStorage.setItem('quality_control_name', e.target.value);
    }

    async function saveVisit(draftStatus) {

        setSaveButtonDisable(true);                               //сразу выключаю кнопку сохранения, чтобы не нажимали дважды

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ complex_name: newVisit?.complexName, client_name: newVisit?.clientName, car_number: newVisit?.carNumber, car_brand: newVisit?.carBrand, car_category: newVisit?.carCategory, tire_radius: newVisit?.tireRadius, chosen_services: chosenServices, chosen_goods: chosenGoods, draft: draftStatus, card_amount: newVisit?.cardAmount, cash_amount: newVisit?.cashAmount, gift_amount: newVisit?.giftAmount, agreement_amount: newVisit?.agreementAmount, loyalty_card_amount: newVisit?.loyaltyCardAmount, total_amount: newVisit?.totalAmount, bonus_amount: newVisit?.bonusAmount, payment_method: newVisit?.paymentMethod, cashier: cashierName, quality_control: qualityControlName})
        };
        const saving_visit = await fetch(api_url+"/api/addVisit", requestOptions);
        const body = await saving_visit.json();
        if (body.message === "Visit added successfully!") {
          setSaveStatus("success");
          window.location.reload();
        }
        else if (body.message === "Total amount error.") {
          setSaveButtonDisable(false);
          setSaveStatus("amount error");
        }
        else if (body.message === "Empty workers list.") {
          setSaveButtonDisable(false);
          setSaveStatus("empty workers list error");
        } 
        else {
          setSaveButtonDisable(false);
          setSaveStatus("error");
        }
    }

    return (
        <div className="add-visit-div">
            <h1 className="modal-header"> Добавить запись </h1>
            <Box sx={{ maxWidth: 400, margin: `auto`, pt: 5}}>

            <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="admin-name-input" label="Администратор:" variant="outlined" name="adminName" value={adminName} InputProps={{ readOnly: true }} />

            {/* Выбор филиала только для учетки офис */}
            { complexName=="Офис" && <>
            <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small"> 
                <InputLabel id="demo-simple-select-label">Выберите филиал</InputLabel>
                <Select
                    variant="outlined"
                    id="complex_name"
                    name="complexName"
                    value={newVisit?.complexName}
                    label="Выберите филиал"
                    onChange={handleChange}
                    fullWidth
                >
                <MenuItem value={"Охта"}>Охта</MenuItem>
                <MenuItem value={"Кудрово"}>Кудрово</MenuItem>
                </Select> 
            </FormControl> 
            </>
            }

            {/* Если не офис, то выбора филиала нет */}
                { complexName!="Офис" && <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="complex-name-input" label="Комплекс:" variant="outlined" name="complexName" value={newVisit?.complexName} onChange={handleChange} InputProps={{ readOnly: true }}/> }

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small"> 
                <InputLabel id="demo-simple-select-label">Кассир</InputLabel>
                <Select
                    variant="outlined"
                    id="cashier_name"
                    name="cashierName"
                    defaulValue="-"
                    value={cashierName}
                    label="Кассир"
                    onChange={handleCashierChange}
                    fullWidth
                >
                  <MenuItem key="default_cashier_name" value="-"> - </MenuItem>
                  {availableCashiers.map(({workerId, fullName}) => (                           //листим список кассиров
                    <MenuItem key={workerId} value={workerId}> {fullName} </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small"> 
                <InputLabel id="demo-simple-select-label">Контроль качества</InputLabel>
                <Select
                    variant="outlined"
                    id="quality_control_name"
                    name="qualityControlName"
                    defaulValue="-"
                    value={qualityControlName}
                    label="Контроль качества"
                    onChange={handleQualityControlChange}
                    fullWidth
                >
                  <MenuItem key="default_qc_name" value="-"> - </MenuItem>
                  {availableQualityControl.map(({workerId, fullName}) => (                           //листим список кассиров
                    <MenuItem key={workerId} value={workerId}> {fullName} </MenuItem>
                    ))}
                  </Select>
                </FormControl> 

                <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="client-name-input" label="Имя клиента:" variant="outlined" name="clientName" value={newVisit?.clientName} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="car-number-input" label="Номер авто:" variant="outlined" name="carNumber" value={newVisit?.carNumber} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="car-brand-input" label="Марка авто:" variant="outlined" name="carBrand" value={newVisit?.carBrand} onChange={handleChange} />
                <FormControl sx={{mb:1.5, mt:1.5}} size="small" fullWidth >
                  <InputLabel id="demo-simple-select-label">Выберите тип автомобиля</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="car-type"
                    name="carCategory"
                    value={newVisit.carCategory}
                    label="Выберите тип автомобиля"
                    onChange={handleChange}
                  >
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                  <MenuItem value={"4"}>4</MenuItem>
                  </Select>
                </FormControl>
            </Box>

          <div>
            <AddService totalAmount={totalAmount} setTotalAmount={setTotalAmount} availableServices={availableServices} chosenServices={chosenServices} setChosenServices={setChosenServices}/>
            <AddGoods availableGoods={availableGoods} chosenGoods={chosenGoods} setChosenGoods={setChosenGoods}/>
          </div>

          {saveStatus === "error" && <div className="button-div"><a>Ошибка, проверьте правильность введенных данных!</a></div>}
          {saveStatus === "amount error" && <div className="button-div"><a>Ошибка, проверьте сумму платежа!</a></div>}
          {saveStatus === "empty workers list error" && <div className="button-div"><a>Необходимо выбрать работников!</a></div>}

          <div className="button-div">
          {saveStatus !== "success" && <button name="saveDraftButton" className="draft-button" disabled = {saveButtonDisable} onClick={() => saveVisit(true)}><a>Сохранить</a></button>}
          {saveStatus !== "success" && paymentStatus && <button name="saveButton" className="save-button" disabled = {saveButtonDisable} onClick={() => saveVisit(false)}><a>Заказ оплачен</a></button>}
          </div>
        </div>
    );
}