import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';

export default function AddService({chosenServices, setChosenServices, availableServices}) {

    const complexName = localStorage.getItem('complex_name');
    const api_url = process.env.REACT_APP_API_URL;
    const [outsourceWorkerSelected, setOutsourceWorkerSelected] = useState(false);    //стейт, который включается при выборе работника аутсорс
    //const OUTSOURCE_WORKER_ID = process.env.OUTSOURCE_WORKER_ID;               //идентификатор работника аутсорс
    const OUTSOURCE_WORKER_ID = ["c1f400d9-7e6b-4d8c-b049-d104f82418ad", "f1bcec02-4918-4570-8bc7-21aeb84e5309"];               //идентификатор работника аутсорс
    const [availableWorkers, setAvailableWorkers] = useState([]);

    useEffect(() => {

    async function getAvailableWorkers() {      //получаем список всех доступных сотрудников
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ complex_name: complexName, role: "car_washer" })
          };
          const availableWorkers = await (await fetch(api_url+"/api/availableWorkers", requestOptions)).json();
          setAvailableWorkers(availableWorkers.map(({worker_id, full_name, rank, phone_number, complex_name, role}) => ({workerId: worker_id, fullName: full_name, rank: rank, phoneNumber: phone_number, complexName: complex_name, role: role})));
    }
    getAvailableWorkers();
  
    }, [chosenServices]);

    function findServiceById(id) {
        return availableServices.find((element) => {
            return element.serviceId === id;
        })
    }

    const handleServiceSelect = (e, index) => {                             //функция, которая вызывается после выбора работы из дропдауна
        const list = [...chosenServices];
        list[index].serviceId = e.target.value;
        list[index].price = findServiceById(e.target.value).price;
        list[index].salary = findServiceById(e.target.value).salary;
        list[index].minRank = findServiceById(e.target.value).minRank;
        list[index].adminSalary = findServiceById(e.target.value).adminSalary;
        setChosenServices(list);
    };

    const handleServiceNumberSelect = (e, index) => {                             //функция, которая вызывается после выбора работы из дропдауна
        const list = [...chosenServices];
        list[index].serviceNumber = e.target.value;
        setChosenServices(list);
    };

    const handleOutsourcePaymentChange = (e, index) => {                             //функция, которая вызывается после ввода суммы аутсорса
        const list = [...chosenServices];
        list[index].outsourcePayment = e.target.value;
        setChosenServices(list);
    };

    const handleChosenWorkerSelect = (e, index) => {                             //функция, которая вызывается после выбора сотрудника из дропдауна
        console.log("select_start" + e.target.value + index);
        const list = [...chosenServices];
        let workers_list = [...chosenServices[index].chosenWorkers];
        workers_list = e.target.value;
        console.log(workers_list);
        list[index].chosenWorkers = workers_list;
        setChosenServices(list);

        // Проверка, содержит ли список выбранных сотрудников аутсорсера
        //const containsSpecialWorker = workers_list.includes(OUTSOURCE_WORKER_ID);
        const containsSpecialWorker = OUTSOURCE_WORKER_ID.some(id => workers_list.includes(id));
        setOutsourceWorkerSelected(containsSpecialWorker);
    };

    const handleRemoveService = (index) => {                               //функция, которая вызывается при удалении работы
        const list = [...chosenServices];
        list.splice(index, 1);
        setChosenServices(list);
        console.log("remove");
    };
    
    const handleAddService = () => {                                       //функция, которая вызывается при добавлении работы
        setChosenServices([...chosenServices, {serviceId: availableServices[0].serviceId, serviceNumber: 1, price: availableServices[0].price, salary: availableServices[0].salary, adminSalary: availableServices[0].adminSalary, chosenWorkers: [], minRank: 1, outsourcePayment: 0}]);    //при добавлении нового сервиса - автоматически подставляется первая услуга
        console.log("add");
    };
   
    return (
        <Box sx={{ maxWidth: 400, margin: `auto`, pt: 1}}>
        <div className="modal-form">
            <label className="add-service-header">Выберите услуги:</label>
            {chosenServices && chosenServices.map((x, current_work_id) => (                             //создаем select по количеству выбранных работ
            <div key={current_work_id}>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Название:</InputLabel>
                <Select
                    key = {current_work_id+1}
                    value={chosenServices[current_work_id].serviceId}
                    label="Название:"
                    name="serviceChoosing"
                    fullWidth
                    onChange={e => handleServiceSelect(e, current_work_id)}
                >   
                    {availableServices && availableServices.map(({serviceId, serviceName, serviceCategory}) => (                           //листим каталог работ
                    <MenuItem key={serviceId} value={serviceId}> {serviceCategory + "/" + serviceName} </MenuItem>
                    ))}
                </Select>
                </FormControl>


                <TextField
                    variant="outlined"
                    key = {current_work_id+2}
                    value={chosenServices[current_work_id].serviceNumber}
                    label="Количество:"
                    name="servicesNumber"
                    size="small"
                    sx={{mb:1.5, mt:1.5}}
                    fullWidth
                    onChange={e => handleServiceNumberSelect(e, current_work_id)}
                />

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small">
                <InputLabel id="demo-multiple-checkbox-label">Сотрудники:</InputLabel>
                <Select

                    key = {current_work_id+3}
                    value={chosenServices[current_work_id].chosenWorkers}
                    label="Сотрудники:"
                    name="workersChosing"
                    fullWidth
                    onChange={e => handleChosenWorkerSelect(e, current_work_id)}
                    multiple
                    input={<OutlinedInput id="select-multiple-chip" label="Сотрудники:" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={availableWorkers && availableWorkers.find((item) => item.workerId === value).fullName} />
                          ))}
                        </Box>
                    )}
                >
                    {availableWorkers.filter(person =>
                    person.rank >= chosenServices[current_work_id].minRank
                    ).map((person) => (                          
                    <MenuItem key={person.workerId} value={person.workerId}> {person.fullName} </MenuItem>
                    ))}
                 </Select>
                </FormControl>

                {chosenServices.length>1 && <button className="add-button" onClick={() => handleRemoveService(current_work_id)}>Удалить работу</button>}

                {outsourceWorkerSelected && (
                <TextField
                    variant="outlined"
                    value = {chosenServices[current_work_id].outsourcePayment}
                    label="ЗП для аутсорсера:"
                    name="outsourcePayment"
                    size="small"
                    sx={{mb:1.5, mt:1.5}}
                    fullWidth
                    onChange={e => handleOutsourcePaymentChange(e, current_work_id)}
                />
                )}
            </div>
            ))}
            <button name="addServiceButton" className="add-button" onClick={handleAddService}><a>Добавить услугу</a></button>
        </div>
        </Box>
    );
}