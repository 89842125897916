import React, { useEffect, useState } from "react";
import EditVisitModal from './edit-visit-modal';
import Modal from '@mui/material/Modal';
import FilterDiv from './filter-div';

export default function DashboardTable({moneyStatus, setMoneyStatus, filterInfo, setFilterInfo}) {

  const [visits, setVisits] = useState([]);
  const [editedVisitId, setEditedVisitId] = useState("");    //показывает айдищник изменяемого визита
  const api_url = process.env.REACT_APP_API_URL;
  const complexName = localStorage.getItem('complex_name');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let gmt3_date_string = new Date().toLocaleString("en-US", { timeZone: "Europe/Moscow" });
  const currentDate = new Date(gmt3_date_string);
  const role = localStorage.getItem('role');

  function showModal(visit_id){
    handleOpen();
    setEditedVisitId(visit_id);
  }

  useEffect(() => {
    async function getAllVisits() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
        body: JSON.stringify({ complex_name: complexName, filter_info: filterInfo })
      };
      const allVisits = await (await fetch(api_url+"/api/allVisits", requestOptions)).json();
      setVisits(allVisits.map(({visit_id, visit_date, client_name, car_number, total_amount, bonus_amount, card_amount, cash_amount, gift_amount, agreement_amount, loyalty_card_amount, draft}) => ({visit_id: visit_id, visit_date: new Date(visit_date).toLocaleString("ru-RU", { timeZone: "UTC" }), client_name: client_name, car_number: car_number, total_amount: total_amount, bonus_amount: bonus_amount, card_amount: card_amount, cash_amount: cash_amount, gift_amount: gift_amount, agreement_amount: agreement_amount, loyalty_card_amount: loyalty_card_amount, draft: draft, expiration: Math.round((currentDate.getTime() - new Date(visit_date).getTime())/ (1000 * 60 * 60 * 24))})));
      getMoneyStatus(allVisits);
    }

    function getMoneyStatus(allVisits) {
      let total_amount = 0;
      let card_amount = 0;
      let cash_amount = 0;
      let bonus_amount = 0;
      let gift_amount = 0;
      let agreement_amount = 0;
      let loyalty_card_amount = 0;
      for (var i = 0; i < allVisits.length; i++) {
          console.log(allVisits[i].draft);
          if (allVisits[i].draft == false) {
            total_amount += parseInt(allVisits[i].total_amount);
            card_amount += parseInt(allVisits[i].card_amount);
            cash_amount += parseInt(allVisits[i].cash_amount);
            bonus_amount += parseInt(allVisits[i].bonus_amount);
            gift_amount += parseInt(allVisits[i].gift_amount);
            agreement_amount += parseInt(allVisits[i].agreement_amount);
            loyalty_card_amount += parseInt(allVisits[i].loyalty_card_amount);
          }
          else {
            console.log("falseeeeee");
          }
      }
      setMoneyStatus({
          ...moneyStatus,
          total_amount: total_amount,
          card_amount: card_amount,
          cash_amount: cash_amount,
          bonus_amount: bonus_amount,
          gift_amount: gift_amount,
          agreement_amount: agreement_amount,
          loyalty_card_amount: loyalty_card_amount
      });
      console.log(process.env);
    }

    getAllVisits();
  
  }, [open, filterInfo]);          //вызывает обновление таблицы после каждого открытия/закрытия модалки


  
  const renderHeader = () => {
    return <tr className="visits-table-header">
      <td className="visits-table-header-cell">Дата</td>
      <td className="visits-table-header-cell">Имя Клиента</td>
      <td className="visits-table-header-cell">Номер Авто</td>
      <td className="visits-table-header-cell">Стоимость</td>
      <td className="visits-table-header-cell">Статус</td>
    </tr>
  }
  
  const renderVisits = () => {
    return visits.map(({ visit_id, visit_date, client_name, car_number, total_amount, draft, expiration }) => {
      return <tr className="visits-table-row" key={visit_id} >
      <td className="visits-table-cell">{visit_date}</td>
      <td className="visits-table-cell">{client_name}</td>
      <td className="visits-table-cell">{car_number}</td>
      <td className="visits-table-cell">{total_amount}</td>
      <td className="visits-table-cell"><RecordStatus draft={draft} /></td>
      { (expiration <= 30 || role == "2") && <td className="visits-table-cell"><button className="visits-table-button" onClick={() => showModal(visit_id)} >Изменить</button></td> }
      {/* <td className="visits-table-cell">{ expiration < 31 || role === "2" && <button className="visits-table-button" onClick={() => showModal(visit_id)} >Изменить</button>}</td>  */}
    </tr>
    })
  }

  return (
    <div>
      <FilterDiv filterInfo={filterInfo} setFilterInfo={setFilterInfo}/>
      <table className="visits-table">
        <tbody>
        {renderHeader()}
        {renderVisits()}
        <Modal
        open={open}
        onClose={handleClose}
        >
        <EditVisitModal setEditVisitModalOpen={setOpen} editedVisitId={editedVisitId}/>
        </Modal>
        </tbody>
      </table>
    </div>
  );
}

function RecordStatus({ draft }) {
  if (draft == false) {
    return <div className="visits-table-status-done">Готово</div>;
  }
  return <div className="visits-table-status-inprogress">В работе</div>;
}