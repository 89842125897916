import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';


export default function EditWorkerModalLP({editedWorkerId, setEditWindowOpen}) {
    const [saveButtonDisable, setSaveButtonDisable] = useState(false); 
    const [deleteButtonDisable, setDeleteButtonDisable] = useState(false); 
    const [saveStatus, setSaveStatus] = useState(""); 
    const api_url = process.env.REACT_APP_API_URL;
    const [workerName, setWorkerName] = useState("");
    const [workerPhoneNumber, setWorkerPhoneNumber] = useState("");
    const [workerRole, setWorkerRole] = useState([]);
    const [workerRank, setWorkerRank] = useState("");
    const [birthDate, setBirthDate] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const availableRoles = [{role_value: "car_washer", role_label:"Мойщик"}, {role_value: "quality_control", role_label:"Контроль качества"}, {role_value: "cashier", role_label:"Кассир"}]

    useEffect(() => {
        async function getWorkerInfo() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ worker_id: editedWorkerId })
          };
          const worker = await (await fetch(api_url+"/api/workerInfo", requestOptions)).json();
          setWorkerName(worker.full_name);
          setWorkerPhoneNumber(worker.phone_number);
          setWorkerRole(worker.role);
          setWorkerRank(worker.rank);
          setBirthDate(worker.birth_date);
        }
        getWorkerInfo();
        }, []);

        const handleDeleteDialogOpen = () => {
          setDeleteDialogOpen(true);
        };
    
        const handleDeleteDialogClose = () => {
          setDeleteDialogOpen(false);
        };

        async function updateWorkerInfo() {
    
            setSaveButtonDisable(true);                               //сразу выключаю кнопку сохранения, чтобы не нажимали дважды
    
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
                body: JSON.stringify({ worker_id: editedWorkerId, phone_number: workerPhoneNumber, full_name: workerName, role: workerRole, rank: workerRank, birth_date: birthDate })
            };

            const saving_visit = await fetch(api_url+"/api/updateWorkerInfo", requestOptions);

            const body = await saving_visit.json();
            if (body.message === "Worker info updated successfully!") {
              setSaveStatus("success");
              setEditWindowOpen(false);
            }
            else {
              setSaveStatus("error");
              setSaveButtonDisable(false);
            }
        }

        async function deleteWorker() {
    
          setDeleteButtonDisable(true);                               //сразу выключаю кнопку удаления, чтобы не нажимали дважды
  
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
              body: JSON.stringify({ worker_id: editedWorkerId })
          };

          const delete_worker = await fetch(api_url+"/api/deleteWorker", requestOptions);

          const body = await delete_worker.json();
          if (body.message === "Worker deleted successfully!") {
            setSaveStatus("success");
            setDeleteDialogOpen(false);
            setEditWindowOpen(false);
          }
          else {
            setSaveStatus("error");
            setSaveButtonDisable(false);
          }
        }

        return (
          
            <div className='edit-worker-modal'>
                <h1 className="modal-header"> Изменить информацию о сотруднике </h1>
                <Box sx={{ maxWidth: 550, margin: `auto`, pt: 5, pb: 5 }}>
                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <TextField
                    id="worker_name"
                    label="Имя"
                    name="fullName"
                    value={workerName}
                    onChange={e => setWorkerName(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ mb: 1.5, mt: 1.5 }} fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DatePicker
                  id="birth-date-picker"
                  label="Дата рождения"
                  name="birthDate"
                  value={birthDate ? dayjs(birthDate) : null}
                  onChange={date => setBirthDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
                </LocalizationProvider>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth size="small" >
                <InputLabel id="demo-multiple-checkbox-label"> Роли:</InputLabel>
                <Select
                value={workerRole}
                label="Роли:"
                name="role"
                fullWidth
                disabled
                InputProps={{
                    disabled: true,
                }}
                onChange={e => setWorkerRole(e.target.value)}
                multiple
                input={<OutlinedInput id="select-multiple-chip" label="Роли:" />}
                        renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={availableRoles && availableRoles.find((item) => item.role_value === value).role_label} />
                            ))}
                        </Box>
                        )}
                >                        
                <MenuItem key="car_washer" value="car_washer"> Мойщик </MenuItem>
                <MenuItem key="quality_control" value="quality_control"> Контроль качества </MenuItem>
                <MenuItem key="cashier" value="cashier"> Кассир </MenuItem>
                </Select>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel id="demo-simple-select-label">Ранг</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="rank_chosing"
                  name="rank"
                  value={workerRank}
                  label="Ранг"
                  onChange={e => setWorkerRank(e.target.value)}
                >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                </Select>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <TextField
                    id="worker_phone"
                    label="Номер телефона"
                    name="phoneNumber"
                    value={workerPhoneNumber}
                    onChange={e => setWorkerPhoneNumber(e.target.value)}
                    />
                </FormControl>

                <Button variant="contained"
                    sx={{mb:1.5, mt:1.5}} fullWidth
                    disabled={saveButtonDisable}
                    color="success"
                    className="mr10"
                    onClick={() => updateWorkerInfo()}>Готово</Button>

              {saveStatus === "error" && <Alert severity="error">Ошибка, проверьте правильность введенных данных!</Alert>}
              {saveStatus === "success" && <Alert severity="success">Изменения успешно сохранены!</Alert>}
              </Box>
            </div>
        );
}