import React, { useEffect, useState } from "react";
import AddAdvance from './add-advance';
import EditAdvance from './edit-advance';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';


export default function Advances() {
    const [addAdvanceOpen, setAddAdvanceOpen] = useState(false);
    const [editAdvanceOpen, setEditAdvanceOpen] = useState(false);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [workerFilter, setWorkerFilter] = useState("all");
    const [editedAdvanceId, setEditedAdvanceId] = useState("");
    const [availableWorkers, setAvailableWorkers] = useState([]);
    const [advancesList, setAdvancesList] = useState([]);
    const api_url = process.env.REACT_APP_API_URL;
    const complexName = localStorage.getItem('complex_name');
    const [totalAdvances, setTotalAdvances] = useState(0);

    const handleAddAdvanceOpen = () => setAddAdvanceOpen(true);
    const handleAddAdvanceClose = () => setAddAdvanceOpen(false);

    const handleEditAdvanceOpen = () => setEditAdvanceOpen(true);
    const handleEditAdvanceClose = () => setEditAdvanceOpen(false);

    function showEditAdvanceModal(advance_id){
        handleEditAdvanceOpen();
        setEditedAdvanceId(advance_id);
    }


    function showAddAdvanceModal(){
        handleAddAdvanceOpen();
    }

    React.useEffect(() => {                                         //функция, которая вызывается при загрузке страницы
        async function getAvailableWorkers() {      //получаем список всех доступных сотрудников
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
              body: JSON.stringify({ complex_name: complexName })
            };
            const availableWorkers = await (await fetch(api_url+"/api/allWorkers", requestOptions)).json();
            setAvailableWorkers(availableWorkers.map(({worker_id, full_name, rank, phone_number, complex_name, role}) => ({workerId: worker_id, fullName: full_name, rank: rank, phoneNumber: phone_number, complexName: complex_name, role: role})));
        }
    
        async function getAdvances() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token')},
            body: JSON.stringify({ start_date: startDate, end_date: endDate, worker_id: workerFilter, complex_name: complexName})
          };
          const advances_list = await fetch(api_url+"/api/allAdvances", requestOptions);
          const body = await advances_list.json();
          setAdvancesList(body.map(({ advance_id, advance_date, worker_id, full_name, advance_amount, complex_name, comment }) => ({ advance_id: advance_id, advance_date: new Date(advance_date).toLocaleString("ru-RU", { timeZone: "UTC" }), worker_id: worker_id, worker_name: full_name, advance_amount: advance_amount, complex_name: complex_name, comment: comment })));
          let temp_total_advances = 0;
          for(var i=0; i < body.length; i++){
            temp_total_advances = temp_total_advances + body[i].advance_amount;
          }

         setTotalAdvances(temp_total_advances);
        }
        getAvailableWorkers();
        getAdvances();
    
        }, [startDate, endDate, workerFilter, addAdvanceOpen, editAdvanceOpen]);

    return (
        <>
            <head>
                <title>Авансы</title>
                <meta property="og:title" content="Advances" key="title" />
            </head>

            <Box sx={{pt:5, pb:5}} fullWidth>
            <TextField
                id="start_date"
                label="Start Date"
                type="date"
                defaultValue={startDate}
                sx={{ m: 1.5, width: 220}}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setStartDate(e.target.value)}
            />

            <TextField
                id="end_date"
                label="End Date"
                type="date"
                defaultValue={endDate}
                sx={{ m: 1.5, width: 220 }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setEndDate(e.target.value)}
            />

            <FormControl sx={{m: 1.5, width: 250}} >
            <InputLabel id="demo-simple-select-label">Выберите сотрудника</InputLabel>
            <Select
                id="worker_name"
                value={workerFilter}
                label="Выберите сотрудника"
                onChange={e => setWorkerFilter(e.target.value)}
            >
            <MenuItem value="all">Любой</MenuItem>
            {availableWorkers.map((item) => (
            <MenuItem key={item.workerId} value={item.workerId}>{item.fullName}</MenuItem>
            ))}
            </Select>
            </FormControl>
            </Box>


            <button className='add-visit-button' onClick={() => showAddAdvanceModal()}>
                <a>Добавить аванс</a>
            </button>

            <Typography m={5} variant="h6">Авансы за выбранный период: {totalAdvances}</Typography>

            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Сотрудник</TableCell>
                <TableCell>Размер Аванса</TableCell>
                {/* <TableCell>Филиал</TableCell> */}
                <TableCell>Комментарий</TableCell>
                <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {advancesList.map(({advance_date, advance_id, worker_name, advance_amount, comment}) => (
            <TableRow>
                <TableCell>{advance_date}</TableCell>
                <TableCell>{worker_name}</TableCell>
                <TableCell>{advance_amount}</TableCell>
                {/* <TableCell>{complex_name}</TableCell> */}
                <TableCell sx={{whiteSpace: "normal", wordWrap: "break-word"}}>{comment}</TableCell>
                <TableCell>
                <Button
                    variant="outlined"
                    className="mr10"
                    onClick={() => showEditAdvanceModal(advance_id)}>Изменить</Button>
                </TableCell>
            </TableRow>
            ))}
            </TableBody>
            </Table>
            </TableContainer>

            <Modal
            open={addAdvanceOpen}
            onClose={handleAddAdvanceClose}
            >
            <AddAdvance availableWorkers={availableWorkers}/>
            </Modal>

            <Modal
            open={editAdvanceOpen}
            onClose={handleEditAdvanceClose}
            >
            <EditAdvance editedAdvanceId={editedAdvanceId} availableWorkers={availableWorkers}/>
            </Modal>
        </>
    )
}

