import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';  

export default function EditServiceModal({editedServiceId, setEditWindowOpen}) {
    const [saveButtonDisable, setSaveButtonDisable] = useState(false); 
    const [saveStatus, setSaveStatus] = useState(""); 
    const api_url = process.env.REACT_APP_API_URL;
    const [serviceId, setServiceId] = useState("");
    const [carCategory, setCarCategory] = useState("");
    const [priceCategory, setPriceCategory] = useState("");
    const [serviceCategory, setServiceCategory] = useState("");
    const [minRank, setMinRank] = useState("");
    const [allServiceCategories, setAllServiceCategories] = useState([]);
    const [serviceName, setServiceName] = useState("");
    const [price, setPrice] = useState("");
    const [salary, setSalary] = useState("");
    const [adminSalary, setAdminSalary] = useState("");
    const [detailing, setDetailing] = useState("");
    const [serviceInfo, setServiceInfo] = useState("");
    const [deleteButtonDisable, setDeleteButtonDisable] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);


        useEffect(() => {
        async function getServiceInfo() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ service_id: editedServiceId })
          };
          const service = await (await fetch(api_url+"/api/serviceInfo", requestOptions)).json();

          setCarCategory(service.car_category);
          setPriceCategory(service.price_category);
          setServiceId(service.service_id);
          setServiceCategory(service.service_category);
          setServiceName(service.service_name);
          setPrice(service.price);
          setSalary(service.salary);
          setAdminSalary(service.admin_salary);
          setDetailing(service.detailing);
          setMinRank(service.min_rank);
        }

        async function getServiceCategories() {
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
              body: JSON.stringify({})
            };
            const serviceCategories = await (await fetch(api_url+"/api/allServiceCategories", requestOptions)).json();
            setAllServiceCategories(serviceCategories.map(({service_category_id, service_category_name}) => ({serviceCategoryId: service_category_id, serviceCategoryName: service_category_name})));
        }

        getServiceInfo();
        getServiceCategories();
        }, []);

        async function updateServiceInfo() {
    
            setSaveButtonDisable(true);                               //сразу выключаю кнопку сохранения, чтобы не нажимали дважды
    
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
                body: JSON.stringify({ service_id: serviceId, car_category: carCategory, service_category: serviceCategory, service_name: serviceName, price: price, salary: salary, admin_salary: adminSalary, price_category: priceCategory, min_rank: minRank, detailing: detailing })
            };

            const saving_service = await fetch(api_url+"/api/updateServiceInfo", requestOptions);

            const body = await saving_service.json();
            if (body.message === "Service info updated successfully!") {
              setSaveStatus("success");
              setEditWindowOpen(false);
            }
            else {
              setSaveStatus("error");
              setSaveButtonDisable(false);
            }
        }

        async function deleteService() {
    
            setDeleteButtonDisable(true);                               //сразу выключаю кнопку удаления, чтобы не нажимали дважды
    
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
                body: JSON.stringify({ service_id: editedServiceId })
            };
  
            const delete_service = await fetch(api_url+"/api/deleteService", requestOptions);
  
            const body = await delete_service.json();
            if (body.message === "Service deleted successfully!") {
                setSaveStatus("success");
                setDeleteDialogOpen(false);
                setEditWindowOpen(false);
            }
            else {
                setSaveStatus("error");
                setSaveButtonDisable(false);
            }
        }
        const handleDeleteDialogOpen = () => {
            setDeleteDialogOpen(true);
        };
      
        const handleDeleteDialogClose = () => {
            setDeleteDialogOpen(false);
        };
  

        return (
            <div className='edit-worker-modal'>
                <h1 className="modal-header"> Изменить информацию об услуге </h1>
                <Box sx={{ maxWidth: 550, margin: `auto`, pt: 5, pb: 5, overflowY: 'auto', maxHeight: '90vh' }}>
                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel>Категория:</InputLabel>
                <Select
                    value={serviceCategory}
                    label="Категория:"
                    name="serviceCategory"
                    fullWidth
                    onChange={e => setServiceCategory(e.target.value)}
                >
                    {allServiceCategories && allServiceCategories.map(({serviceCategoryId, serviceCategoryName}) => (                           //листим каталог работ
                    <MenuItem key={serviceCategoryId} value={serviceCategoryName}> {serviceCategoryName} </MenuItem>
                    ))}
                </Select>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <TextField
                    id="service_name"
                    label="Название"
                    name="serviceName"
                    value={serviceName}
                    onChange={e => setServiceName(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel id="demo-simple-select-label">Филиал</InputLabel>
                <Select
                    key = {priceCategory}
                    id="price-category"
                    label="Филиал"
                    name="priceCategory"
                    value={priceCategory}
                    onChange={e => setPriceCategory(e.target.value)}
                >
                <MenuItem value={"all"}>Все филиалы</MenuItem>
                <MenuItem value={"other"}>Кудрово</MenuItem>
                <MenuItem value={"ohta_only"}>Охта</MenuItem>
                </Select>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel>Выберите тип автомобиля</InputLabel>
                <Select
                    id="car-type"
                    name="carCategory"
                    value={carCategory}
                    label="Выберите тип автомобиля"
                    onChange={e => setCarCategory(e.target.value)}
                >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={"all"}>Любой</MenuItem>
                </Select>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel>Минимальный ранг работника</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="min-rank"
                    name="minRank"
                    value={minRank}
                    label="Минимальный ранг работника"
                    onChange={e => setMinRank(e.target.value)}
                >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                </Select>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <TextField
                    id="service_price"
                    label="Стоимость"
                    name="price"
                    value={price}
                    onChange={e => setPrice(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <TextField
                    id="service_salary"
                    label="ЗП"
                    name="salary"
                    value={salary}
                    onChange={e => setSalary(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <TextField
                    id="service_admin_salary"
                    label="ЗП Администратора"
                    name="admin_salary"
                    value={adminSalary}
                    onChange={e => setAdminSalary(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                    <InputLabel id="demo-simple-select-label">Детейлинг</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="detailing"
                        name="detailing"
                        value={detailing}
                        label="Детейлинг"
                        onChange={e => setDetailing(e.target.value)}
                    >
                    <MenuItem value={"yes"}>Да</MenuItem>
                    <MenuItem value={"no"}>Нет</MenuItem>
                    </Select>
                </FormControl>
        
                <Button variant="contained"
                    sx={{mb:1.5, mt:1.5}} fullWidth
                    disabled={saveButtonDisable}
                    color="success"
                    className="mr10"
                    onClick={() => updateServiceInfo()}>Сохранить изменения</Button>

                <Button variant="contained"
                    sx={{mb:1.5, mt:1.5}} fullWidth
                    onClick={handleDeleteDialogOpen}>
                    Удалить услугу
                </Button>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                  {"Вы действительно хотите удалить услугу?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Для удаления услуги нажмите "Удалить", для отмены - нажмите "Отмена".
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDeleteDialogClose}>Отмена</Button>
                <Button variant="contained"
                    disabled={deleteButtonDisable} 
                    onClick={() => deleteService()}>
                    Удалить
                </Button>
                </DialogActions>
                </Dialog>

        {saveStatus === "error" && <Alert severity="error">Ошибка, проверьте правильность введенных данных!</Alert>}
        {saveStatus === "success" && <Alert severity="success">Изменения успешно сохранены!</Alert>}
        </Box>
            </div>
        );
}