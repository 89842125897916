import React, { useEffect, useState } from "react";

export default function MoneyWindow({moneyStatus}) {

return(
    <div className="money-window">
        <h1 className="choose-worker-header"> Поступления в кассу: </h1>
        <h2>{moneyStatus?.card_amount + moneyStatus?.cash_amount + moneyStatus?.agreement_amount} ₽</h2>
        <h4 className="money-modal-header">Оплата наличными: </h4>
        <a>{moneyStatus?.cash_amount} ₽</a>
        <h4 className="money-modal-header">Оплата безналом: </h4>
        <a>{moneyStatus?.card_amount} ₽</a>
        <h4 className="money-modal-header">Оплата по договору: </h4>
        <a>{moneyStatus?.agreement_amount} ₽</a>
        <h4 className="money-modal-header">Оплата бонусами: </h4>
        <a>{moneyStatus?.bonus_amount} ₽</a>
        <h4 className="money-modal-header">Оплата сертификатом: </h4>
        <a>{moneyStatus?.gift_amount} ₽</a>
        <h4 className="money-modal-header">Оплата картой лояльности: </h4>
        <a>{moneyStatus?.loyalty_card_amount} ₽</a>
    </div>
)
}