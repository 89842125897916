import { useState } from 'react';

export default function useToken() {
    
  const getToken = () => {
    const userToken = localStorage.getItem('token');
    //const userToken = JSON.parse(tokenString);
    //return userToken?.token
    return userToken
    //return tokenString;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', userToken.token);
    localStorage.setItem('role', userToken.role);
    localStorage.setItem('complex_name', userToken.complex_name);
    localStorage.setItem('admin_name', userToken.full_name);
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}