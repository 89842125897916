import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import logo from '../logo.png';
const api_url = process.env.REACT_APP_API_URL;

export default function Login({ setToken }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginStatus, setLoginStatus] = useState();

    async function loginAction() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: username, password: password })
        };
        const login = await fetch(api_url+"/api/login", requestOptions);
        const login_data = await login.json();
        setToken(login_data);
        setLoginStatus(login_data.message);
        // localStorage.setItem('token', JSON.stringify(login_data.token));
    }

    return(
        <div className="App">
            <Box sx={{ maxWidth: 550, margin: `auto`, pt: 5, pb: 5}}>
                <p><img className="login-logo" src={logo} width="100" height="100" alt="Triton Logo"/></p>
                <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="username-input" label="Логин:" variant="outlined" name="username" value={username} onChange={e => setUsername(e.target.value)} />
                <TextField sx={{mb:1.5, mt:1.5}} size="small" fullWidth id="password-input" label="Пароль:" type="password" variant="outlined" name="password" value={password} onChange={e => setPassword(e.target.value)} />
                <Button variant="contained" sx={{mb:1.5, mt:1.5}} fullWidth onClick={loginAction}> Войти </Button>
                {loginStatus === "Incorrect login or password!" && <Alert severity="error">Неверный логин и пароль!</Alert>}
                {loginStatus === "Login successful!" && <Alert severity="success">Успешно!</Alert>}
            </Box>
        </div>

    )
}