import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function FilterDiv({filterInfo, setFilterInfo}) {

    const [availableWorkers, setAvailableWorkers] = useState([]);
    const api_url = process.env.REACT_APP_API_URL;
    const complexName = localStorage.getItem('complex_name');

    useEffect(() => {
      async function getAvailableWorkers() {      //получаем список всех доступных сотрудников
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
          body: JSON.stringify({ complex_name: complexName, role: "car_washer" })
        };
        const availableWorkers = await (await fetch(api_url+"/api/availableWorkers", requestOptions)).json();
        setAvailableWorkers(availableWorkers.map(({worker_id, full_name, rank, phone_number, complex_name, role}) => ({workerId: worker_id, fullName: full_name, rank: rank, phoneNumber: phone_number, complexName: complex_name, role: role})));
      }

      getAvailableWorkers();

    }, []);

    function handleChange(e) {
        setFilterInfo({
        ...filterInfo,
        [e.target.name]: e.target.value
        });
    }

    return (
        <div className='filter-div'>
            <Box sx={{pt:5, pb:5}} fullWidth>
                <TextField
                    id="start_date"
                    label="Start Date"
                    type="date"
                    name="startDate"
                    value={filterInfo?.startDate}
                    sx={{ m: 1.5, width: 140}}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleChange}
                />
                <TextField
                    id="end_date"
                    label="End Date"
                    type="date"
                    name="endDate"
                    value={filterInfo?.endDate}
                    sx={{ m: 1.5, width: 140 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleChange}
                />

                <FormControl sx={{m: 1.5, width: 140}}>
                <InputLabel id="demo-simple-select-label">Сотрудник</InputLabel>
                <Select
                    variant="outlined"
                    id="worker_name"
                    name="workerId"
                    value={filterInfo?.workerId}
                    label="Сотрудник"
                    // sx={{mb:1.5, mt:1.5}}
                    onChange={handleChange}
                    fullWidth
                >
                <MenuItem value="any">Любой</MenuItem>
                {availableWorkers && availableWorkers.map((item) => (
                <MenuItem key={item?.workerId} value={item?.workerId}>{item?.fullName}</MenuItem>
                ))}
                </Select>
                </FormControl>

                {/* Выбор филиала только для учетки офис */}
                { complexName=="Офис" && <>
                <FormControl sx={{m: 1.5, width: 120}}> 
                <InputLabel id="demo-simple-select-label">Филиал</InputLabel>
                <Select
                    variant="outlined"
                    id="complex_name"
                    name="complexName"
                    value={filterInfo?.complexName}
                    label="Филиал"
                    // sx={{mb:1.5, mt:1.5}}
                    onChange={handleChange}
                    fullWidth
                >
                <MenuItem value={"all"}>Любой</MenuItem>
                <MenuItem value={"Кудрово"}>Кудрово</MenuItem>
                <MenuItem value={"Охта"}>Охта</MenuItem>
                </Select> 
                </FormControl>
                </>
                }   

                <FormControl sx={{m: 1.5, width: 120}}> 
                <InputLabel id="demo-simple-select-label">Способ оплаты</InputLabel>
                <Select
                    variant="outlined"
                    id="payment_method"
                    name="paymentMethod"
                    value={filterInfo?.paymentMethod}
                    label="Способ оплаты"
                    // sx={{mb:1.5, mt:1.5}}
                    onChange={handleChange}
                    fullWidth
                >
                <MenuItem value={"any"}>Любой</MenuItem>
                <MenuItem value={"card"}>По карте</MenuItem>
                <MenuItem value={"cash"}>Наличными</MenuItem>
                <MenuItem value={"mixed"}>Смешанный</MenuItem>
                </Select> 
                </FormControl> 

                <TextField
                    sx={{ m: 1.5, width: 140}}
                    id="client-name-input"
                    label="Имя клиента:"
                    variant="outlined"
                    name="clientName"
                    placeholder="Любой"
                    value={filterInfo?.clientName}
                    onChange={handleChange} />
                
                <TextField
                    sx={{ m: 1.5, width: 140}}
                    id="car-number-input"
                    label="Номер авто:"
                    variant="outlined"
                    name="carNumber"
                    placeholder="Любой"
                    value={filterInfo?.carNumber}
                    onChange={handleChange} />
                
          </Box>
    </div>
  );
}