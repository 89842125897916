import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';


export default function EditPriceDiv({visitInfo, setVisitInfo}) {

    const [paymentMethod, setPaymentMethod] = useState(""); //костыль для отображения paymentmethod в селекте, почему-то visitInfo.paymentMethod не работал

    function setPaymentByCard() {
        setVisitInfo({
            ...visitInfo,
            cardAmount: visitInfo?.totalAmount,
            cashAmount: 0,
            bonusAmount: 0,
            giftAmount: 0,
            agreementAmount: 0,
            loyaltyCardAmount: 0
        });
    }

    function setPaymentByCash() {
        setVisitInfo({
            ...visitInfo,
            cashAmount: visitInfo?.totalAmount,
            cardAmount: 0,
            bonusAmount: 0,
            giftAmount: 0,
            agreementAmount: 0,
            loyaltyCardAmount: 0
        });
    }

    function setMixedPayment() {
        setVisitInfo({
            ...visitInfo,
            paymentMethod: "mixed"
        });
    }

    useEffect(() => {

        setPaymentMethod(visitInfo.paymentMethod);

        async function checkPaymentMethod() {
          if (visitInfo.paymentMethod === "card") { 
            setPaymentByCard();
          }
          else if (visitInfo.paymentMethod === "cash") { 
            setPaymentByCash();
          }
          else if (visitInfo.paymentMethod === "mixed") { 
            setMixedPayment();
          }

        }

        checkPaymentMethod();

    }, [visitInfo.paymentMethod, visitInfo.totalAmount]);


    function handleChange(e) {
        setVisitInfo({
          ...visitInfo,
          [e.target.name]: e.target.value
        });
    }

    return(
        <div className="price-div">
            <h1 className="choose-worker-header"> Итоговая сумма: </h1>
            <h1 className="choose-worker-header">{visitInfo.totalAmount}</h1>
            <FormControl sx={{mb:5, mt:4}}>
            <InputLabel id="payment-method-label">Способ оплаты:</InputLabel>
            <Select
                    label="Способ оплаты:"
                    name="paymentMethod"
                    value={paymentMethod}
                    sx={{width:200}}
                    onChange={e => handleChange(e)}
                >
                <MenuItem key={"card"} value="card"> По карте </MenuItem>
                <MenuItem key={"cash"} value="cash"> Наличными </MenuItem>
                <MenuItem key={"mixed"} value="mixed"> Смешанная оплата </MenuItem>
            </Select>
            </FormControl>
            {visitInfo.paymentMethod === "mixed" && <div className="mixed-payment-div">
                <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата безналом:" variant="outlined" name="cardAmount" value={visitInfo.cardAmount} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата наличными:" variant="outlined" name="cashAmount" value={visitInfo.cashAmount} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата бонусами:" variant="outlined" name="bonusAmount" value={visitInfo.bonusAmount} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата сертификатом:" variant="outlined" name="giftAmount" value={visitInfo.giftAmount} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата по договору:" variant="outlined" name="agreementAmount" value={visitInfo.agreementAmount} onChange={handleChange} />
                <TextField sx={{mb:1.5, mt:1.5, width:200}} size="small" id="client-name-input" label="Оплата картой лояльности:" variant="outlined" name="loyaltyCardAmount" value={visitInfo.loyaltyCardAmount} onChange={handleChange} />
            </div>}
        </div>
    )
}