import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function AddAdvance({availableWorkers}) {                
  
const [newAdvance, setNewAdvance]=useState([]);
const api_url = process.env.REACT_APP_API_URL;
const complex_name = localStorage.getItem('complex_name');
const [saveStatus, setSaveStatus] = useState("");
const [saveButtonDisable, setSaveButtonDisable] = useState(false);             

function handleChange(e) {
    setNewAdvance({
      ...newAdvance,
      [e.target.name]: e.target.value
    });
}

async function createAdvance() {
    
    setSaveButtonDisable(true);                               //сразу выключаю кнопку сохранения, чтобы не нажимали дважды

    let worker_info = availableWorkers.find((s) => s.workerId === newAdvance.workerId)
    let full_name = worker_info.fullName;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
        body: JSON.stringify({worker_id: newAdvance.workerId, full_name: full_name, advance_amount: newAdvance.advanceAmount, complex_name: complex_name, comment: newAdvance.comment})
    };

    const saving_visit = await fetch(api_url+"/api/addAdvance", requestOptions);

    const body = await saving_visit.json();
    if (body.message === "Advance added successfully!") {
      setSaveStatus("success");
    }
    else {
      setSaveStatus("error");
      setSaveButtonDisable(false);
    }
}

return (
    <div className='edit-worker-modal'>
    <h1 className="modal-header"> Добавить аванс </h1>
      <Box sx={{ maxWidth: 550, margin: `auto`, pt: 5, pb: 5 }}>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel id="demo-simple-select-label">Сотрудник:</InputLabel>
        <Select
          value={newAdvance.worker_id}
          label="Сотрудник:"
          name="workerId"
          fullWidth
          onChange={handleChange}
        >
        {availableWorkers.map(({workerId, fullName}) => (                           //листим каталог работ
        <MenuItem key={workerId} value={workerId}> {fullName} </MenuItem>
        ))}
        </Select>
        </FormControl>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel htmlFor="component-outlined">Сумма</InputLabel>
        <OutlinedInput
          name="advanceAmount"
          value={newAdvance.advanceAmount}
          onChange={handleChange}
          label="Сумма"
        />
        </FormControl>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel htmlFor="component-outlined">Комментарий</InputLabel>
        <OutlinedInput
          id="comment"
          name="comment"
          value={newAdvance.comment}
          onChange={handleChange}
          label="Комментарий"
        />
        </FormControl>
        
        <Button variant="contained"
            sx={{mb:1.5, mt:1.5}} fullWidth
            disabled={saveButtonDisable}
            color="success"
            className="mr10"
            onClick={() => createAdvance()}>Добавить аванс</Button>
        {saveStatus === "error" && <Alert severity="error">Ошибка, проверьте правильность введенных данных!</Alert>}
        {saveStatus === "success" && <Alert severity="success">Изменения успешно сохранены!</Alert>}
        </Box>
    </div>
)}