import React, { useState, useEffect } from "react";
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditWorkerModalLP from './edit-worker-modal-lp';
import AddWorkerModalLP from './add-worker-modal-lp';
import Button from '@mui/material/Button';

export default function WorkersListLP() {

    const [carWashers, setCarWashers] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [addWorkerOpen, setAddWorkerOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleAddWorkerOpen = () => setAddWorkerOpen(true);
    const handleAddWorkerClose = () => setAddWorkerOpen(false);
    const api_url = process.env.REACT_APP_API_URL;
    const [editedWorkerId, setEditedWorkerId] = useState("");

    useEffect(() => {
        async function getCarWashersList() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ complex_name: 'Охта' })
          };
          const carWashers = await (await fetch(api_url+"/api/carWashers", requestOptions)).json();
          setCarWashers(carWashers.map(({worker_id, full_name, phone_number, rank, complex_name}) => ({workerId: worker_id, fullName: full_name, phoneNumber: phone_number, rank: rank, complexName: complex_name})));
        }
        getCarWashersList();
  
    }, [open, addWorkerOpen]);

    function showModal(visit_id){
        handleOpen();
        setEditedWorkerId(visit_id);
    }

    function showAddWorkerModal(){
        handleAddWorkerOpen();
    }

    return (
        <div>
            <button className='add-visit-button' onClick={() => showAddWorkerModal()}>
                <a>Добавить сотрудника</a>
            </button>
            <div className='workers-table'>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Имя</TableCell>
                    <TableCell>Номер телефона</TableCell>
                    <TableCell>Ранг</TableCell>
                    <TableCell>Комплекс</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {carWashers.map(({ workerId, fullName, phoneNumber, rank, complexName }) => (
                <TableRow>
                    <TableCell>{fullName}</TableCell>
                    <TableCell>{phoneNumber}</TableCell>
                    <TableCell>{rank}</TableCell>
                    <TableCell>{complexName.join(', ')}</TableCell>
                    <TableCell>
                    <Button
                        variant="outlined"
                        className="mr10"
                        onClick={() => showModal(workerId)}>Изменить</Button>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
            </div>
            <Modal
            open={open}
            onClose={handleClose}
            >
            <EditWorkerModalLP editedWorkerId={editedWorkerId} setEditWindowOpen={setOpen}/>
            </Modal>
            <Modal
            open={addWorkerOpen}
            onClose={handleAddWorkerClose}
            >
            <AddWorkerModalLP />
            </Modal>
        </div>
    )
}

